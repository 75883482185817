import React, {useRef} from 'react';
import { SignatureInputElementClass } from 'types/documentTemplateElement';
import { ElementProps } from 'types/documentTemplateElement';
import SignatureInput from "../../inputs/SignatureInput";
import SignatureCanvas from "react-signature-canvas";

const SignatureInputElementComponent: React.FC<ElementProps<SignatureInputElementClass>> = ({ }) => {
    const signatureCanvasRef = useRef<SignatureCanvas>(null);
  return (
    <div className="p-4">
      <SignatureInput
          signatureCanvasRef={signatureCanvasRef}
          disabled={true}
      />
    </div>
  );
};

export default SignatureInputElementComponent;
