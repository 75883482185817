import { DocumentTemplateService } from 'services/documentTemplateService';
import { createEntityThunks } from 'utils/createEntityThunks';
import { DocumentTemplate } from 'types/documentTemplate';
import {createAsyncThunk} from "@reduxjs/toolkit";

const documentTemplateFactory = (data: any) => new DocumentTemplate(data);

class BaseDocumentTemplateThunks extends createEntityThunks<DocumentTemplate>(
    'documentTemplate',
    DocumentTemplateService,
    { activate: true, deactivate: true },
    documentTemplateFactory
){}

export class DocumentTemplateThunks extends BaseDocumentTemplateThunks {
    static duplicate = createAsyncThunk('documentTemplate/duplicate', async (id: string) => {
        return await DocumentTemplateService.duplicate(id)
    });
}
