import React, {useContext, useEffect, useState} from 'react';
import Sidebar from 'components/SideBar';
import {Outlet} from 'react-router-dom';
import useMinHeight from 'hooks/useMinHeight';
import {selectIsSidebarOpen} from 'features/sidebar/sidebarSlice';
import {useSelector} from 'react-redux';
import HeaderChangeApp from 'components/HeaderChangeApp';
import HeaderProfile from 'components/HeaderProfile';
import Drawer from 'components/Drawer';
import ProfileEdit from 'features/user/ProfileEdit';
import {TitleContext} from 'components/TitleContext';
import UpdatePassword from 'features/user/UpdatePassword';
import UpdatePin from '../features/user/UpdatePin';
import UpdateSignature from '../features/user/UpdateSignature';

interface MainLayoutProps {}
const MainLayout: React.FC<MainLayoutProps> = () => {
  const {minHeight, contentRef, minContentHeight, updateHeights} =
    useMinHeight();
  const isSidebarOpen = useSelector(selectIsSidebarOpen);
  const [isProfileDrawerOpen, setIsProfileDrawerOpen] = useState(false);
  const closeProfileDrawer = () => setIsProfileDrawerOpen(false);
  const closePasswordDrawer = () => setIsPasswordDrawerOpen(false);
  const {title} = useContext(TitleContext);
  const [isPasswordDrawerOpen, setIsPasswordDrawerOpen] = useState(false);
  const [isPinDrawerOpen, setIsPinDrawerOpen] = useState(false);
  const [isSignatureDrawerOpen, setIsSignatureDrawerOpen] = useState(false);
  const closePinDrawer = () => setIsPinDrawerOpen(false);
  const closeSignatureDrawer = () => setIsSignatureDrawerOpen(false);

  useEffect(() => {
    updateHeights();
    window.addEventListener('resize', updateHeights);
    return () => {
      window.removeEventListener('resize', updateHeights);
    };
  });

  return (
    <div className="" ref={contentRef}>
      <div className="w-52">
        <Sidebar setIsNewDrawerOpen={setIsProfileDrawerOpen} />
      </div>
      <div
        className={`transition-all duration-300 ${isSidebarOpen ? 'ml-52' : 'ml-[84px]'} pr-3`}
        style={{height: minContentHeight}}>
        <main
          id="content"
          style={{minHeight}}
          className="flex-grow pt-5 bg-gray-50 rounded-xl">
          <div className="pr-5 pl-5 pb-3">
            <div className="pb-6 flex items-center justify-between">
              <div className="flex flex-col">{title}</div>
              <div className="flex items-center space-x-4">
                <HeaderChangeApp />
                <HeaderProfile
                  setIsProfileDrawerOpen={setIsProfileDrawerOpen}
                  setIsPasswordDrawerOpen={setIsPasswordDrawerOpen}
                  setIsPinDrawerOpen={setIsPinDrawerOpen}
                  setIsSignatureDrawerOpen={setIsSignatureDrawerOpen}
                />
              </div>
            </div>
            <Outlet />
            <Drawer isOpen={isPasswordDrawerOpen} onClose={closePasswordDrawer}>
              <UpdatePassword onClose={closePasswordDrawer} />
            </Drawer>
            <Drawer isOpen={isProfileDrawerOpen} onClose={closeProfileDrawer}>
              <ProfileEdit onClose={closeProfileDrawer} />
            </Drawer>
            <Drawer isOpen={isPinDrawerOpen} onClose={closePinDrawer}>
              <UpdatePin onClose={closePinDrawer} />
            </Drawer>
            <Drawer
              isOpen={isSignatureDrawerOpen}
              onClose={closeSignatureDrawer}>
              <UpdateSignature
                onClose={closeSignatureDrawer}
                isDrawerOpen={isSignatureDrawerOpen}
              />
            </Drawer>
          </div>
        </main>
        <div className="pb-3"></div>
      </div>
    </div>
  );
};

export default MainLayout;
