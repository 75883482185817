import React from 'react';

interface BlockedProps {
    className?: string
}

const BlockedIcon = ({}: BlockedProps) => (
    <svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="16" height="16" fill="none"/>
        <g id="minus-circle">
            <path id="Vector"
                  d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                  stroke="currentColor" strokeWidth="2"   strokeLinecap="round" strokeLinejoin="round"/>
            <path id="Vector_2" d="M8 12H16" strokeWidth="2"  stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
    </svg>

);

export default BlockedIcon;
