import React from 'react';
import {formatSSN} from 'utils/caseConverter';

interface SSNDisplayProps {
  value: string;
}

const SSNDisplay: React.FC<SSNDisplayProps> = ({value}) => {
  if (!value) {
    return <span>-</span>;
  }
  return <span>{formatSSN(value)}</span>;
};

export default SSNDisplay;
