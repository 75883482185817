import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store/store";
import React, {useEffect, useState} from "react";
import TextInput from "components/inputs/TextInput";
import {BackgroundDocument} from "types/backgroundDocument";
import SubmitButton from "components/SubmitButton";
import {BackgroundDocumentThunks} from "features/backgroundDocument/backgroundDocumentThunks";
import {setIsEditing} from "features/backgroundDocument/backgroundDocumentSlice";
import XIcon from "assets/images/icons/XIcon";
import DescriptionInput from "components/inputs/DescriptionInput";

interface BackgroundDocumentFormProps {
    id?: string;
    closeDrawer: () => void;
}

const BackgroundDocumentForm: React.FC<BackgroundDocumentFormProps> = ({closeDrawer}) => {
    const backgroundDocument = useSelector((state: RootState) => state.backgroundDocument.backgroundDocument);
    const dispatch = useDispatch<AppDispatch>();
    const [updatedBackgroundDocument, setUpdatedBackgroundDocument] = useState({...backgroundDocument});
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);

    useEffect(() => {
        const isFormValid =
            updatedBackgroundDocument.name
        setIsSaveDisabled(!isFormValid);
    }, [
        updatedBackgroundDocument.name,
    ]);

    useEffect(() => {
        setUpdatedBackgroundDocument({...backgroundDocument})
        setIsSaveDisabled(!backgroundDocument?.name);
    }, [backgroundDocument]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const {id, value} = e.target;
        setUpdatedBackgroundDocument({...updatedBackgroundDocument, [id]: value});
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (backgroundDocument?.id) {
            await dispatch(BackgroundDocumentThunks.update(new BackgroundDocument(updatedBackgroundDocument))).then(() => closeDrawer());
        } else {
            await dispatch(BackgroundDocumentThunks.create(new BackgroundDocument(updatedBackgroundDocument))).then(() => closeDrawer());
        }
    };

    const handleClose = () => {
        dispatch(setIsEditing(false));
        closeDrawer();
    };

    return (
        <div className="flex flex-col h-screen">
            <div className="flex-1 flex flex-col">
                <div
                    className="h-auto px-6 pt-6 pb-6 bg-sky-50 border-b border-sky-200 flex justify-between items-start gap-2 w-full">
                    <div className="flex flex-col justify-start items-start gap-1">
                        <div className="text-lg font-semibold tracking-normal text-cyan-800">
                            {backgroundDocument?.id ? 'Edit Background Document' : 'Create Background Document'}
                        </div>
                        <div className="text-sm font-light tracking-normal leading-5 text-zinc-400">
                            {backgroundDocument?.id ? 'Edit' : 'Create a new '} Background Document
                        </div>

                    </div>
                    <button onClick={handleClose} className="text-gray-500 hover:text-gray-700 focus:outline-none pt-2">
                        <XIcon/>
                    </button>
                </div>
                <div className="h-full px-6 pb-6 ">
                    <div className="mt-5">
                        <form onSubmit={handleSubmit}>
                        <div className="flex flex-col space-y-6 items-center mt-7">
                                <div className="w-full">
                                    <TextInput
                                        id="name"
                                        type="text"
                                        label="Name"
                                        value={updatedBackgroundDocument?.name}
                                        placeholder=""
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="w-full">
                                    <DescriptionInput
                                        id="description"
                                        type="text"
                                        label="Description"
                                        value={updatedBackgroundDocument?.description}
                                        placeholder=""
                                        onChange={handleChange}
                                        optional="optional"
                                    />
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <footer className="flex-shrink-0 px-6 pb-4 pt-2 flex justify-end gap-5">
                <div className="border-t border-slate-200 w-full">
                    <div
                        className="flex w-full justify-center items-center gap-x-2 pb-4">
                        <SubmitButton onClick={handleSubmit}
                                      label={(backgroundDocument?.id ? 'Save' : 'Create new') + ' Background Document'}
                                      disabled={isSaveDisabled}
                        />
                    </div>
                    <div
                        className="flex w-full justify-center items-center gap-x-2 pb-4">
                        <button type="submit" onClick={handleClose}
                                className="self-center text-sm font-light tracking-normal leading-5 text-center text-zinc-400 hover:text-cyan-400">
                            Never mind
                        </button>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default BackgroundDocumentForm;
