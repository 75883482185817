import React from 'react';

interface BackgroundImageProps {
  src: string;
  alt: string;
}

const SidePanel: React.FC<BackgroundImageProps> = ({src, alt}) => {
  return (
    <div className="flex flex-col">
      <img
        loading="lazy"
        src={src}
        className="rounded-3xl md:object-cover object-cover xl:w-full h-full overflow-hidden"
        alt={alt}
      />
    </div>
  );
};

export default SidePanel;
