import { useEffect, useRef } from 'react';
import {AuthThunks} from "features/auth/authThunks";
import useAppDispatch from "./useAppDispatch";
import {useSelector} from "react-redux";
import {RootState} from "store/store";
import {setHrLocked} from "features/auth/authSlice";


const useIdleTimer = (timeout = 3600000) => {
    const dispatch = useAppDispatch();
    const timerRef = useRef<NodeJS.Timeout | null>(null);
    const hrTimerRef = useRef<NodeJS.Timeout | null>(null);
    const isLocked = useSelector((state: RootState) => state.auth.isLocked);
    const hrLocked = useSelector((state: RootState) => state.auth.hrLocked);
    const token = useSelector((state: RootState) => state.auth.token);

    const resetTimer = () => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(() => {
            if(isLocked) return;
            if(token === null) return;

            dispatch(AuthThunks.lockScreen());
        }, timeout);
    };

    useEffect(() => {
        const events = ['mousemove', 'keydown', 'click', 'scroll'];

        const eventHandler = () => {
            resetTimer();
        };

        events.forEach(event => window.addEventListener(event, eventHandler));

        resetTimer();

        return () => {
            events.forEach(event => window.removeEventListener(event, eventHandler));
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, [dispatch, timeout, token, isLocked]);

    useEffect(() => {
        // Clear any existing hrLocked timer
        if (hrTimerRef.current) {
            clearTimeout(hrTimerRef.current);
        }

        // Start the hrLocked timer if the user is logged in
        if (token) {
            hrTimerRef.current = setTimeout(() => {
                if (hrLocked || !token) return;
                dispatch(setHrLocked(true));
            }, 900000); // 15 minutes in milliseconds
        }

        // Clean up the hrLocked timer on unmount or when dependencies change
        return () => {
            if (hrTimerRef.current) {
                clearTimeout(hrTimerRef.current);
            }
        };
    }, [dispatch, token, hrLocked]);

    return null;
};

export default useIdleTimer;
