import React, {useEffect} from 'react';
import DrawerSkeleton from './DrawerSkeleton';

interface DrawerProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  loading?: boolean;
}

const Drawer: React.FC<DrawerProps> = ({
  isOpen,
  onClose,
  children,
  loading,
}) => {
  useEffect(() => {
    if (isOpen) {
      document.getElementsByTagName('body')[0].classList.add('overflow-hidden');
    } else {
      document
        .getElementsByTagName('body')[0]
        .classList.remove('overflow-hidden');
    }
  }, [isOpen]);

  return (
    <div
      id="drawer-toggle"
      className={`fixed inset-0 overflow-hidden ${
        isOpen
          ? 'translate-x-0 opacity-100 z-50'
          : 'translate-x-full opacity-0 z-0'
      }`}>
      <div className="absolute inset-0 overflow-hidden">
        <div
          className="absolute inset-0 bg-gray-500 bg-opacity-75 "
          aria-hidden="true"
          onClick={onClose}></div>
        <div
          className={`fixed inset-y-0 right-0 flex max-w-full pl-10 transition-transform duration-300 ease-in-out transform ${
            isOpen ? 'translate-x-0' : 'translate-x-full'
          }`}>
          <div className="w-screen max-w-md">
            <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
              <div className="min-h-0 flex-1 flex flex-col overflow-y-scroll overflow-x-hidden">
                <div className="flex-1">
                  <div className={loading ? '' : 'hidden'}>
                    <DrawerSkeleton />
                  </div>
                  <div className={loading ? 'hidden' : ''}>{children}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Drawer;
