import { toSnakeCaseString } from './caseConverter';
import { SearchField } from 'types/search';

interface BuildUrlParams {
    baseUrl: string;
    page?: number;
    search?: { where: { [key: string]: any } };
    sortField?: string;
    sortDirection?: string;
    rowsPerPage?: number;
}
export const toQueryString = (search: Record<string, SearchField>) => {
    return Object.entries(search).map(([key, { operator, value, valueArray }]) => {
        if (!value && !valueArray) return '';

        let encodedValue: string;
        if (valueArray) {
            return valueArray.map((v) => `where[${key}][operator]=${operator}&where[${key}][value_array][]=${encodeURIComponent(String(v))}`).join('&');
        } else if (value instanceof Date) {
            encodedValue = encodeURIComponent(value.toISOString());
        } else {
            encodedValue = encodeURIComponent(String(value));
        }
        return `where[${key}][operator]=${operator}&where[${key}][value]=${encodedValue}`;

    }).join('&');
};

export const buildUrlWithParams = ({
                                       baseUrl,
                                       page,
                                       search = { where: {} },
                                       sortField,
                                       sortDirection,
                                       rowsPerPage,
                                   }: BuildUrlParams): string => {
    let url = baseUrl;

    const params: { [key: string]: any } = {};

    if (page !== undefined) {
        params['page'] = page;
    }

    if (sortField && sortDirection) {
        params[`sort[${toSnakeCaseString(sortField)}]`] = sortDirection;
    }

    if (rowsPerPage !== undefined) {
        params['items'] = rowsPerPage;
    }

    const queryString = toQueryString(search.where);
    if (queryString && queryString !== '&') {
        url += `?${queryString}`;
    }

    const paramString = new URLSearchParams(params).toString();
    if (paramString) {
        url += (url.includes('?') ? '&' : '?') + paramString;
    }

    return url;
};
