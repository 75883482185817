import React from "react";

const ArrowBottomIcon = () => (
    <svg className="flex-shrink-0 size-3.5" xmlns="http://www.w3.org/2000/svg" width="24"
         height="24"
         viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5"
         strokeLinecap="round"
         strokeLinejoin="round">
        <path d="M12 5v14"/>
        <path d="m19 12-7 7-7-7"/>
    </svg>
);
export default ArrowBottomIcon;
