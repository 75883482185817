import React, { useState} from "react";
import TableHeader from "components/table/TableHeader";
import TableRow from "components/table/TableRow";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "store/store";
import TableCell from "components/table/TableCell";
import {DocumentTemplate} from "types/documentTemplate";
import Table from "components/table/Table";
import {SearchType} from "types/search";
import BlueButton from "components/BlueButton";
import PlusIcon from "assets/images/icons/PlusIcon";
import {useNavigate} from "react-router-dom";
import {DocumentTemplateThunks} from "features/documentTemplate/documentTemplateThunks";
import {useDropdownActionMenu} from "hooks/useDropdownActionMenu";
import {setActiveTab, setDocumentTemplate} from "features/documentTemplate/documentTemplateSlice";
import TrashIcon from "assets/images/icons/TrashIcon";
import CheckCircleIcon from "assets/images/icons/CheckCircleIcon";
import XCircleIcon from "assets/images/icons/XCircleIcon";
import CircleDashedIcon from "assets/images/icons/CircleDashedIcon";
import DotsMenu from "components/DotsMenu";
import WhiteButton from "../../components/WhiteButton";
import DownloadIcon from "../../assets/images/icons/DownloadIcon";


const DocumentTemplateTable: React.FC<{ openDrawer: () => void }> = () => {
    const documentTemplates = useSelector((state: RootState) => state.documentTemplate.documentTemplates);
    const dispatch = useDispatch<AppDispatch>();
    const pagy = useSelector((state: RootState) => state.documentTemplate.pagy);
    const navigate = useNavigate();
    const activeTab = useSelector((state: RootState) => state.documentTemplate.activeTab);
    const {setDotsMenuIsOpen} = useDropdownActionMenu();


    const [visibleColumns, setVisibleColumns] = useState({
        name: true,
        status: true,
        created: true,
        createdBy: true,
        updatedAt: true,
        updatedBy: true
    });

    interface VisibleColumnsType {
        name: boolean,
        status: boolean,
        created: boolean,
        createdBy: boolean,
        updatedAt: boolean,
        updatedBy: boolean
    }

    const searchByOptions = [
        {label: 'Name', value: 'name', operator: 'like'},
    ];

    const columnOptions = [
        {label: 'Name', value: 'name', isVisible: visibleColumns.name},
        {label: 'Status', value: 'status', isVisible: visibleColumns.status},
        {label: 'Created', value: 'created', isVisible: visibleColumns.created},
        {label: 'Created by', value: 'createdBy', isVisible: visibleColumns.createdBy},
        {label: 'Updated', value: 'updatedAt', isVisible: visibleColumns.updatedAt},
        {label: 'Updated by', value: 'updatedBy', isVisible: visibleColumns.updatedBy},
    ];

    const fetchData = async ({page, search, sortField, sortDirection, rowsPerPage}: {
        page: number;
        search: SearchType<{ [key: string]: { operator: string, value: string } }>;
        sortField: string;
        sortDirection: string;
        rowsPerPage: number
    }): Promise<void> => {
        if (activeTab === 'Active' || activeTab === 'Inactive' || activeTab === 'Draft') {
            search.where.status = {
                operator: '==',
                value: activeTab.toLowerCase()
            };
        } else {
            delete search.where.status;
        }
        await dispatch(DocumentTemplateThunks.index({page, search, sortField, sortDirection, rowsPerPage}));
    };

    const handleColumnToggle = (column: string) => {
        if (column in visibleColumns) {
            setVisibleColumns((prev) => ({
                ...prev,
                [column]: !prev[column as keyof VisibleColumnsType],
            }));
        }
    };
    const handleDelete = async (id: string) => {
        await dispatch(DocumentTemplateThunks.delete(id));
        setDotsMenuIsOpen(null);
    };
    const handleDeactivate = (documentTemplateId: string) => {
        dispatch(DocumentTemplateThunks.deactivate(documentTemplateId));
        setDotsMenuIsOpen(null);
    };

    const handleReactivate = (documentTemplateId: string) => {
        dispatch(DocumentTemplateThunks.activate(documentTemplateId));
        setDotsMenuIsOpen(null);
    };

    const handleDownload = (_id: string) => async () => {

    };

    const handleDuplicate = (id: string) => async () => {
        await dispatch(DocumentTemplateThunks.duplicate(id));
    }

    const handleOpen = (id?: string) => {
        if (id) {
            navigate(`/document-templates/${id}`);
        } else {
            dispatch(setDocumentTemplate(null));
            navigate(`/document-templates/new`);
        }
    };

    return (
        <Table
            fetchData={fetchData}
            pagy={pagy}
            pageable={true}
            recordsName={'Documents'}
            activeTab={activeTab}
            setActiveTab={(tab: string) => dispatch(setActiveTab(tab))}
            tabs={['All', 'Active', 'Inactive', 'Draft']}
            searchable={true}
            columns={true}
            columnOptions={columnOptions}
            onColumnToggle={handleColumnToggle}
            searchByOptions={searchByOptions}
            whiteButton={<WhiteButton onClick={()=>{}} label="" icon={<DownloadIcon/>}/>}
            blueButton={<BlueButton onClick={() => handleOpen(undefined)} label="" icon={<PlusIcon/>}/>}
        >
            <thead>
            <tr>
                <th scope="col" className="px-3  pt-3.5  flex justify-center">
                    <input type="checkbox"
                           className="shrink-0 border-stone-300 rounded disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800"/>
                </th>
                {visibleColumns.name && <TableHeader label="Name" sortBy="name"/>}
                {visibleColumns.status && <TableHeader label="Status" sortBy="status"/>}
                {visibleColumns.created && <TableHeader label="Created" sortBy="created"/>}
                {visibleColumns.createdBy && <TableHeader label="Created by" sortBy="createdBy"/>}
                {visibleColumns.updatedAt && <TableHeader label="Updated" sortBy="updatedAt"/>}
                {visibleColumns.updatedBy && <TableHeader label="Updated by" sortBy="updatedBy"/>}
            </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">
            {documentTemplates.map((documentTemplate: DocumentTemplate) => {
                const userOptions = [];
                userOptions.push({
                    label: "Edit",
                    onClick: handleOpen,
                    hoverClass: "hover:bg-cyan-100",
                });
                userOptions.push({
                    label: " Download",
                    onClick: handleDownload(documentTemplate.id),
                    hoverClass: "hover:bg-cyan-100",
                });
                userOptions.push({
                    label: " Duplicate",
                    onClick: handleDuplicate(documentTemplate.id),
                    hoverClass: "hover:bg-cyan-100",
                });
                if (documentTemplate.status === "Draft") {
                    userOptions.push({
                        label: "Publish",
                        onClick: () => handleReactivate(documentTemplate.id),
                        hoverClass: "hover:bg-red-100 hover:text-red-800",
                    });
                }
                
                if (documentTemplate.status === "Active") {
                    userOptions.push({
                        label: "Deactivate",
                        onClick: () => handleDeactivate(documentTemplate.id),
                        hoverClass: "hover:bg-red-100 hover:text-red-800",
                    });
                }
                if (documentTemplate.status === "Inactive") {
                    userOptions.push({
                        label: "Publish",
                        onClick: () => handleReactivate(documentTemplate.id),
                        hoverClass: "hover:bg-cyan-100",
                    });

                }
                userOptions.push({
                    label: "Delete",
                    onClick: () => handleDelete(documentTemplate.id),
                    icon: <TrashIcon/>,
                    hoverClass: "hover:bg-red-100 hover:text-red-800",
                });
                return (
                    <TableRow
                        key={documentTemplate.id}
                    >
                        <td scope="col" className="pt-3.5 flex justify-center">
                            <input
                                type="checkbox"
                                className="border border-gray-300 rounded text-white disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                                onClick={(e) => e.stopPropagation()}/>
                        </td>
                        {visibleColumns.name &&
                            <TableCell>
                                <div className=" relative group">
                                    <div className="w-full flex items-center gap-x-3">
                                        <div className="flex-grow">
                                        <span
                                            className="text-sm  cursor-pointer font-medium dark:text-neutral-200 underline text-cyan-600"
                                            onClick={() => handleOpen(documentTemplate.id)}
                                        >
                                            {documentTemplate.name}
                                        </span>
                                        </div>
                                    </div>
                                </div>
                            </TableCell>}
                        {visibleColumns.status && (
                            <TableCell>
                            <span
                                className={`flex items-center text-xs font-medium px-2 py-0.5 rounded-full w-28
                                    ${documentTemplate.status === 'Active' ? 'bg-green-100 text-green-800' : ''}
                                    ${documentTemplate.status === 'Inactive' ? 'bg-red-100 text-red-800' : ''}
                                    ${documentTemplate.status === 'Draft' ? 'bg-cyan-100 text-cyan-800' : ''}
                                    `}
                            >
                                    {documentTemplate.status === 'Active' && <CheckCircleIcon className="mr-2"/>}
                                    {documentTemplate.status === 'Inactive' && <XCircleIcon className="mr-2"/>}
                                    {documentTemplate.status === 'Draft' && <CircleDashedIcon className="mr-2"/>}

                                <div className="pl-1">
                                        {documentTemplate.status}
                                    </div>
                                </span>
                            </TableCell>)}

                        {visibleColumns.created && <TableCell>{documentTemplate.createdAt}</TableCell>}
                        {visibleColumns.createdBy && <TableCell>{documentTemplate.createdBy}</TableCell>}
                        {visibleColumns.updatedAt && <TableCell>{documentTemplate.updatedAt}</TableCell>}
                        {visibleColumns.updatedBy && <TableCell>{documentTemplate.updatedBy}</TableCell>}
                        <TableCell>
                            <DotsMenu options={userOptions}/>
                        </TableCell>
                    </TableRow>
                )
            })}
            </tbody>
        </Table>
    );
};

export default DocumentTemplateTable;
