import { createEntitySlice } from 'utils/createEntitySlice';
import { DocumentTemplate } from 'types/documentTemplate';
import {DocumentTemplateThunks} from "features/documentTemplate/documentTemplateThunks";
import {showToast} from "../../components/ToastContainer";

export interface documentTemplateState {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    documentTemplates: DocumentTemplate[];
    documentTemplate: DocumentTemplate | null;
    isEditing: boolean;
    activeTab: string;
    pagy: any;
}

const initialState: Partial<documentTemplateState> = {
    documentTemplates: [],
    documentTemplate: null,
    isEditing: false,
    activeTab: 'All',
    pagy: {},
};

const documentTemplateSlice = createEntitySlice<DocumentTemplate>({
    name: 'Document',
    initialState,
    thunks: DocumentTemplateThunks,
    entityKey: 'documentTemplate',
    entitiesKey: 'documentTemplates',
    reducers: {
    },
    extraReducers: {
        [DocumentTemplateThunks.duplicate.pending.toString()]: (state) => {
          state.status = 'loading';
        },
        [DocumentTemplateThunks.duplicate.fulfilled.toString()]: (state, action) => {
            state['documentTemplates'] = [action.payload, ...state['documentTemplates']];
            showToast('Successfully duplicated', 'success');
        }
    }
});

export const { setEntity: setDocumentTemplate, setIsEditing, setActiveTab } = documentTemplateSlice.actions;
export default documentTemplateSlice.reducer;
