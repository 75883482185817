import React from 'react';
import {ElementProps, YesNoInputElementClass} from "types/documentTemplateElement";
import RadioInput from "../../inputs/RadioInput";

interface YesNoInputElementProps extends ElementProps<YesNoInputElementClass> {}

const YesNoInputElementComponent: React.FC<YesNoInputElementProps> = ({ element }) => {
    return (
        <div className="w-full m-8">
            <RadioInput
                label={element.properties.showLabel ? element.properties.label : ""}
                options={[
                    {id: 'Yes', label: 'Yes', value: true},
                    {id: 'No', label: 'No', value: false}
                ]}
                selectedValue={undefined}
                onChange={() => {}}
                required={element.properties.required}
            />
        </div>
    );
};

export default YesNoInputElementComponent;
