// TextInputProperties.tsx
import React from 'react';
import {TextInputElement} from 'types/documentTemplateElement';
import RadioInputHorizontal from "../../inputs/RadioInputHorizontal";
import {DocumentTemplate} from "../../../types/documentTemplate";

interface TextBlockInputPropertiesProps {
    element: TextInputElement;
    template: DocumentTemplate;
    updateElement: (updatedElement: TextInputElement) => void;
}

const TextBlockInputProperties: React.FC<TextBlockInputPropertiesProps> = ({
                                                                     element,
                                                                     template,
                                                                     updateElement,
                                                                 }) => {

    return (
        <div className="space-y-5 w-full">

            {template.requiresReview && (
                <RadioInputHorizontal
                    label="Admin Only"
                    options={[
                        {id: 'adminOnly-yes', label: 'Yes', value: true},
                        {id: 'adminOnly-no', label: 'No', value: false},
                    ]}
                    selectedValue={element.properties.adminOnly}
                    onChange={(value) => updateElement({...element, properties: {...element.properties, adminOnly: value}})}
                />
            )}
            {!template.requiresReview && (
                <p className="text-slate-400 text-sm font-medium">
                This element doesn't have any properties
                </p>
            )}
        </div>
    );
};

export default TextBlockInputProperties;
