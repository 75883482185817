import React from 'react';

const DashboardIcon = () => (
    <svg width="20" height="20" strokeWidth="1.5"viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="trello">
            <path id="Vector"
                  d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z"
                  stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
            <path id="Vector_2" d="M10 7H7V16H10V7Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
            <path id="Vector_3" d="M17 7H14V12H17V7Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
    </svg>
);
export default DashboardIcon;