import {createSlice} from "@reduxjs/toolkit";


export interface SettingsState {
    myDashboardTab: string;
}

const initialState: Partial<SettingsState> = {
    myDashboardTab: 'Missing Documents',
};
const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setMyDashboardTab: (state, action) => {
            state.myDashboardTab = action.payload;
        },
    },
});

export const { setMyDashboardTab} = settingsSlice.actions;
export default settingsSlice.reducer;
