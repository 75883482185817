import React from "react";

interface TextInputProps {
    id: string;
    type: string;
    label: string;
    value: string;
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void | undefined;
    onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void | undefined;
    error?: string | undefined;
    placeholder?: string;
    tabIndex?: number;
    disabled?: boolean;
    required?: boolean;
    optional?: string;
}

const DescriptionInput: React.FC<TextInputProps> = ({
                                                        id,
                                                        label,
                                                        value,
                                                        onChange,
                                                        onBlur,
                                                        error,
                                                        placeholder,
                                                        tabIndex,
                                                        disabled,
                                                        required,
                                                        optional,
                                                    }) => {
    return (
        <div className="flex flex-col items-start gap-[0.625rem]">
            <div className="flex justify-between w-full">
                <label
                    htmlFor={id}
                    className="block grow shrink basis-0 text-slate-800 text-sm font-semibold font-['Inter'] dark:text-white">
                    {label}
                </label>
                <span className="text-gray-400 text-sm">{optional}</span>
            </div>
            <textarea
                id={id}
                data-testid={`${id}-field`}
                value={value}
                onChange={onChange}
                className={`w-full h-[75px] py-2.5 px-3 block focus:outline-cyan-400 border border-slate-200  rounded-lg text-sm text-gray-500 font-light placeholder:text-gray-400 
                            disabled:opacity-50 disabled:pointer-events-none  disabled:bg-slate-100  disabled:text-slate-300
                            dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60  
                            dark:focus:ring-neutral-600 ${error ? "border-red-500" : "border-gray-200"}`}
                placeholder={placeholder}
                aria-describedby={`${id}-error`}
                tabIndex={tabIndex}
                disabled={disabled}
                onBlur={onBlur}
                rows={4}
                required={required}
            />
            {error && (
                <p className="text-xs text-red-600 " id={`${id}-error`}>
                    {error}
                </p>
            )}
        </div>
    );
};

export default DescriptionInput;
