import React from 'react';
import {ElementProps, MultiLineTextInputElementClass} from "types/documentTemplateElement";
import DescriptionInput from "../../inputs/DescriptionInput";

interface MultiLineTextInputElementProps extends ElementProps<MultiLineTextInputElementClass> {}

const MultiLineTextInputElementComponent: React.FC<MultiLineTextInputElementProps> = ({ element }) => {
    return (
        <div className="w-full m-8">
            <DescriptionInput
                id={element.id}
                type="text"
                label={element.properties.showLabel ? element.properties.label : ""}
                value=""
                placeholder={element.properties.placeholder}
                required={element.properties.required}
                onChange={() => {}}
            />
        </div>
    );
};

export default MultiLineTextInputElementComponent;
