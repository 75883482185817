import React from 'react';

interface SubmitButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  label: React.ReactNode;
  disabled: boolean;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
}

const SubmitButton: React.FC<SubmitButtonProps> = ({
  onClick,
  label,
  disabled,
  className,
  type = 'button',
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={`${className || 'w-full mt-6 py-2.5 px-3 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-cyan-400 text-white hover:bg-cyan-500 disabled:pointer-events-none disabled:bg-slate-100  disabled:text-slate-300 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600'}`}
      disabled={disabled}>
      {label}
    </button>
  );
};

export default SubmitButton;
