import {createEntityService} from "../utils/createEntityService";
import {UploadRequest} from "../types/uploadRequest";
import apiClient from "services/apiClient";

export const BaseUploadRequestService = createEntityService<UploadRequest>({
    baseUrl: 'api/v1/upload_requests',
    entityName: 'uploadRequest',
    methodConfigs: {}
});

export class UploadRequestService extends BaseUploadRequestService {

    static upload = async (formData: any) => {
        const response = await apiClient.post('api/v1/upload_requests/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
        return response.data;
    }

};