import React from "react";

const TableCell: React.FC<{
  children: React.ReactNode;
  className?: string;
  onClick?: () => Promise<void>;
}> = ({children}) => (
  <td className=" size-x  whitespace-nowrap px-6 py-2">
    <span className="text-sm text-gray-600 dark:text-neutral-400 ">
      {children}
    </span>
  </td>
);

export default TableCell;
