import apiClient from 'services/apiClient';
import {AxiosRequestConfig} from 'axios';
import {buildUrlWithParams} from 'utils/apiUtils';
import {SearchType} from 'types/search';

interface EntityServiceOptions<_EntityType> {
    baseUrl: string;
    entityName: string;
    methodConfigs?: {
        create?: AxiosRequestConfig;
        update?: AxiosRequestConfig;
        index?: AxiosRequestConfig;
        show?: AxiosRequestConfig;
        delete?: AxiosRequestConfig;
    };
}

function hasToJsonMethod(obj: any): obj is { toJson: () => any } {
    return obj && typeof obj.toJson === 'function';
}

export function createEntityService<EntityType>(
    options: EntityServiceOptions<EntityType>
) {
    const {baseUrl, entityName, methodConfigs = {}} = options;
    return class EntityService {
        static async index(
            page?: number,
            search?: SearchType<Record<string, any>>,
            sortField?: string,
            sortDirection?: string,
            rowsPerPage?: number,
            config?: AxiosRequestConfig
        ) {
            const url = buildUrlWithParams({
                baseUrl,
                page,
                search,
                sortField,
                sortDirection,
                rowsPerPage,
            });

            const response = await apiClient.get(url, {
                ...methodConfigs.index,
                ...config,
            });
            return response.data;
        }

        static async create(record: EntityType, config?: AxiosRequestConfig) {
            let data: any;
            if (hasToJsonMethod(record)) {
                data = record.toJson();
            } else {
                data = record;
            }

            const response = await apiClient.post(
                baseUrl,
                {[entityName]: data},
                {...methodConfigs.create, ...config}
            );
            return response.data;
        }

        static async show(id: string, config?: AxiosRequestConfig) {
            const response = await apiClient.get(
                `${baseUrl}/${id}`,
                {...methodConfigs.show, ...config}
            );
            return response.data;
        }

        static async update(record: EntityType & { id: string }, config?: AxiosRequestConfig) {
            let data: any;
            if (hasToJsonMethod(record)) {
                data = record.toJson();
            } else {
                data = record;
            }

            const response = await apiClient.patch(
                `${baseUrl}/${record.id}`,
                {[entityName]: data},
                {...methodConfigs.update, ...config}
            );
            return response.data;
        }

        static async delete(id: string, config?: AxiosRequestConfig) {
            const response = await apiClient.delete(
                `${baseUrl}/${id}`,
                {...methodConfigs.delete, ...config}
            );
            if (response.status === 204) {
                return id;
            }
            return response.data;
        }
    };
}
