import React from "react";

const ArrowTopIcon = () => (
<svg className="flex-shrink-0 size-3.5" xmlns="http://www.w3.org/2000/svg" width="16"
     height="16"
     viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5"
     strokeLinecap="round"
     strokeLinejoin="round">
    <path d="m5 12 7-7 7 7"/>
    <path d="M12 19V5"/>
</svg>
);
export default ArrowTopIcon;
