import React from 'react';
import { DateTimeInputElementClass } from 'types/documentTemplateElement';
import { ElementProps } from 'types/documentTemplateElement';
import DateTimeInput from "../../inputs/DateTimeInput";

const DateTimeInputElementComponent: React.FC<ElementProps<DateTimeInputElementClass>> = ({ element }) => {
  return (
      <div className="w-full m-8">
        <DateTimeInput
            id={element.id}
            label={element.properties.showLabel ? element.properties.label : ""}
            value=""
            placeholder={element.properties.placeholder}
            required={element.properties.required}
            onChange={() => {}}
            isClickDisabled={true}
            allowPastDates={element.properties.allowPastDates}

        />

      </div>
  );
};

export default DateTimeInputElementComponent;
