export type DocumentElementType =
    | 'TextBlock'
    | 'TextInput'
    | 'MultiLineTextInput'
    | 'Dropdown'
    | 'YesNoInput'
    | 'DateInput'
    | 'PhoneNumberInput'
    | 'SsnInput'
    | 'TableInput'
    | 'EmailInput'
    | 'AddressInput'
    | 'CurrencyInput'
    | 'DateTimeInput'
    | 'TimeInput'
    | 'RatingInput'
    | 'SignatureInput'
    | 'UrlInput'

export interface DocumentTemplateElement {
    id: string
    elementType: DocumentElementType
    _destroy?: boolean
    properties: any
}

export interface ElementProps<T extends DocumentTemplateElement> {
    element: T
    updateElement: (updatedElement: T) => void
}

export interface TableInputElementProperties {
    label: string
    rows: number
    columns: number
    showLabel: boolean
    adminOnly: boolean
    data: string[][]
}
export interface DropdownElementProperties {
    label: string
    isMulti: boolean
    required: boolean
    adminOnly: boolean
    options: string[]
    showLabel: boolean
}

export interface AddressInputElementProperties {
    label: string
    required: boolean
    adminOnly: boolean
    showLabel: boolean
    placeholder?: string
}

export interface CurrencyInputElementProperties {
    label: string
    required: boolean
    adminOnly: boolean
    showLabel: boolean
    placeholder?: string
    currencySymbol?: string
}

export interface DateTimeInputElementProperties {
    label: string
    required: boolean
    adminOnly: boolean
    showLabel: boolean
    placeholder?: string
    allowPastDates: boolean
}

export interface TimeInputElementProperties {
    label: string
    required: boolean
    adminOnly: boolean
    showLabel: boolean
    placeholder?: string
}

export interface RatingInputElementProperties {
    label: string
    required: boolean
    adminOnly: boolean
    showLabel: boolean
    maxRating: number
    initialRating?: number
}

export interface SignatureInputElementProperties {
    label: string
    required: boolean
    adminOnly: boolean
    showLabel: boolean
}

export interface UrlInputElementProperties {
    label: string
    required: boolean
    adminOnly: boolean
    showLabel: boolean
    placeholder?: string
}

export interface TextBlockElementProperties {
    content: string
}

export interface TextInputElementProperties {
    label: string
    placeholder?: string
    required: boolean
    showLabel: boolean
    adminOnly: boolean
}

export interface MultiLineTextInputElementProperties {
    label: string
    placeholder?: string
    required: boolean
    showLabel: boolean
    adminOnly: boolean
}

export interface PhoneNumberInputElementProperties {
    label: string
    placeholder?: string
    required: boolean
    showLabel: boolean
    phoneNumber: string
    adminOnly: boolean
}

export interface YesNoInputElementProperties {
    label: string
    required: boolean
    showLabel: boolean
    adminOnly: boolean
}

export interface DateInputElementProperties {
    label: string
    required: boolean
    adminOnly: boolean
    showLabel: boolean
    placeholder: string
    allowPastDates: boolean
}

export interface SsnInputElementProperties {
    label: string
    required: boolean
    showLabel: boolean
    adminOnly: boolean
}

export interface EmailInputElementProperties {
    label: string
    required: boolean
    showLabel: boolean
    placeholder?: string
    adminOnly: boolean
}

export interface DropdownElement extends DocumentTemplateElement {
    elementType: 'Dropdown'
    properties: DropdownElementProperties
}

export interface AddressInputElement extends DocumentTemplateElement {
    elementType: 'AddressInput'
    properties: AddressInputElementProperties
}

export interface CurrencyInputElement extends DocumentTemplateElement {
    elementType: 'CurrencyInput'
    properties: CurrencyInputElementProperties
}

export interface DateTimeInputElement extends DocumentTemplateElement {
    elementType: 'DateTimeInput'
    properties: DateTimeInputElementProperties
}

export interface TimeInputElement extends DocumentTemplateElement {
    elementType: 'TimeInput'
    properties: TimeInputElementProperties
}

export interface RatingInputElement extends DocumentTemplateElement {
    elementType: 'RatingInput'
    properties: RatingInputElementProperties
}

export interface SignatureInputElement extends DocumentTemplateElement {
    elementType: 'SignatureInput'
    properties: SignatureInputElementProperties
}

export interface UrlInputElement extends DocumentTemplateElement {
    elementType: 'UrlInput'
    properties: UrlInputElementProperties
}

export interface TextBlockElement extends DocumentTemplateElement {
    elementType: 'TextBlock'
    properties: TextBlockElementProperties
}

export interface TextInputElement extends DocumentTemplateElement {
    elementType: 'TextInput'
    properties: TextInputElementProperties
}

export interface MultiLineTextInputElement extends DocumentTemplateElement {
    elementType: 'MultiLineTextInput'
    properties: MultiLineTextInputElementProperties
}

export interface PhoneNumberInputElement extends DocumentTemplateElement {
    elementType: 'PhoneNumberInput'
    properties: PhoneNumberInputElementProperties
}

export interface YesNoInputElement extends DocumentTemplateElement {
    elementType: 'YesNoInput'
    properties: YesNoInputElementProperties
}

export interface DateInputElement extends DocumentTemplateElement {
    elementType: 'DateInput'
    properties: DateInputElementProperties
}

export interface SsnInputElement extends DocumentTemplateElement {
    elementType: 'SsnInput'
    properties: SsnInputElementProperties
}

export interface EmailInputElement extends DocumentTemplateElement {
    elementType: 'EmailInput'
    properties: EmailInputElementProperties
}

export interface TableInputElement extends DocumentTemplateElement {
    elementType: 'TableInput'
    properties: TableInputElementProperties
}

export interface BaseElementProps<P> {
    id: string
    _destroy?: boolean
    elementType: DocumentElementType
    properties: P
}

export class BaseElementClass<P> implements DocumentTemplateElement {
    id: string
    _destroy?: boolean
    elementType: DocumentElementType
    properties: P
    constructor(data: BaseElementProps<P>) {
        this.id = data.id
        this._destroy = data._destroy
        this.elementType = data.elementType
        this.properties = data.properties
    }
    toJson(): DocumentTemplateElement {
        const idIsNumeric = /^\d+$/.test(this.id);
        return {
            id: idIsNumeric ? this.id : '',
            elementType: this.elementType,
            _destroy: this._destroy,
            properties: this.properties
        }
    }
}

export class DropdownElementClass
    extends BaseElementClass<DropdownElementProperties>
    implements DropdownElement {
    elementType: 'Dropdown'
    constructor(data: DropdownElement) {
        super({
            ...data,
            elementType: 'Dropdown',
            properties: {
                label: data.properties.label,
                isMulti: data.properties.isMulti,
                required: data.properties.required,
                options: data.properties.options,
                showLabel: data.properties.showLabel,
                adminOnly: data.properties.adminOnly
            }
        })
        this.elementType = 'Dropdown'
    }
}

export class TextBlockElementClass
    extends BaseElementClass<TextBlockElementProperties>
    implements TextBlockElement {
    elementType: 'TextBlock'
    constructor(data: TextBlockElement) {
        super({
            ...data,
            elementType: 'TextBlock',
            properties: {
                content: data.properties.content
            }
        })
        this.elementType = 'TextBlock'
    }
}

export class TextInputElementClass
    extends BaseElementClass<TextInputElementProperties>
    implements TextInputElement {
    elementType: 'TextInput'
    constructor(data: TextInputElement) {
        super({
            ...data,
            elementType: 'TextInput',
            properties: {
                label: data.properties.label,
                placeholder: data.properties.placeholder,
                required: data.properties.required,
                showLabel: data.properties.showLabel,
                adminOnly: data.properties.adminOnly
            }
        })
        this.elementType = 'TextInput'
    }
}

export class MultiLineTextInputElementClass
    extends BaseElementClass<MultiLineTextInputElementProperties>
    implements MultiLineTextInputElement {
    elementType: 'MultiLineTextInput'
    constructor(data: MultiLineTextInputElement) {
        super({
            ...data,
            elementType: 'MultiLineTextInput',
            properties: {
                label: data.properties.label,
                placeholder: data.properties.placeholder,
                required: data.properties.required,
                showLabel: data.properties.showLabel,
                adminOnly: data.properties.adminOnly
            }
        })
        this.elementType = 'MultiLineTextInput'
    }
}

export class PhoneNumberInputElementClass
    extends BaseElementClass<PhoneNumberInputElementProperties>
    implements PhoneNumberInputElement {
    elementType: 'PhoneNumberInput'
    constructor(data: PhoneNumberInputElement) {
        super({
            ...data,
            elementType: 'PhoneNumberInput',
            properties: {
                label: data.properties.label,
                placeholder: data.properties.placeholder,
                required: data.properties.required,
                showLabel: data.properties.showLabel,
                phoneNumber: data.properties.phoneNumber,
                adminOnly: data.properties.adminOnly
            }
        })
        this.elementType = 'PhoneNumberInput'
    }
}

export class YesNoInputElementClass
    extends BaseElementClass<YesNoInputElementProperties>
    implements YesNoInputElement {
    elementType: 'YesNoInput'
    constructor(data: YesNoInputElement) {
        super({
            ...data,
            elementType: 'YesNoInput',
            properties: {
                label: data.properties.label,
                required: data.properties.required,
                showLabel: data.properties.showLabel,
                adminOnly: data.properties.adminOnly
            }
        })
        this.elementType = 'YesNoInput'
    }
}

export class SsnInputElementClass
    extends BaseElementClass<SsnInputElementProperties>
    implements SsnInputElement {
    elementType: 'SsnInput'
    constructor(data: SsnInputElement) {
        super({
            ...data,
            elementType: 'SsnInput',
            properties: {
                label: data.properties.label,
                required: data.properties.required,
                showLabel: data.properties.showLabel,
                adminOnly: data.properties.adminOnly
            }
        })
        this.elementType = 'SsnInput'
    }
}

export class DateInputElementClass
    extends BaseElementClass<DateInputElementProperties>
    implements DateInputElement {
    elementType: 'DateInput'
    constructor(data: DateInputElement) {
        super({
            ...data,
            elementType: 'DateInput',
            properties: {
                label: data.properties.label,
                required: data.properties.required,
                showLabel: data.properties.showLabel,
                placeholder: data.properties.placeholder,
                allowPastDates: data.properties.allowPastDates,
                adminOnly: data.properties.adminOnly
            }
        })
        this.elementType = 'DateInput'
    }
}

export class EmailInputElementClass
    extends BaseElementClass<EmailInputElementProperties>
    implements EmailInputElement {
    elementType: 'EmailInput'
    constructor(data: EmailInputElement) {
        super({
            ...data,
            elementType: 'EmailInput',
            properties: {
                label: data.properties.label,
                required: data.properties.required,
                showLabel: data.properties.showLabel,
                placeholder: data.properties.placeholder,
                adminOnly: data.properties.adminOnly
            }
        })
        this.elementType = 'EmailInput'
    }
}

export class AddressInputElementClass
    extends BaseElementClass<AddressInputElementProperties>
    implements AddressInputElement {
    elementType: 'AddressInput'
    constructor(data: AddressInputElement) {
        super({
            ...data,
            elementType: 'AddressInput',
            properties: {
                label: data.properties.label,
                required: data.properties.required,
                showLabel: data.properties.showLabel,
                placeholder: data.properties.placeholder,
                adminOnly: data.properties.adminOnly
            }
        })
        this.elementType = 'AddressInput'
    }
}

export class CurrencyInputElementClass
    extends BaseElementClass<CurrencyInputElementProperties>
    implements CurrencyInputElement {
    elementType: 'CurrencyInput'
    constructor(data: CurrencyInputElement) {
        super({
            ...data,
            elementType: 'CurrencyInput',
            properties: {
                label: data.properties.label,
                required: data.properties.required,
                showLabel: data.properties.showLabel,
                placeholder: data.properties.placeholder,
                currencySymbol: data.properties.currencySymbol,
                adminOnly: data.properties.adminOnly
            }
        })
        this.elementType = 'CurrencyInput'
    }
}

export class DateTimeInputElementClass
    extends BaseElementClass<DateTimeInputElementProperties>
    implements DateTimeInputElement {
    elementType: 'DateTimeInput'
    constructor(data: DateTimeInputElement) {
        super({
            ...data,
            elementType: 'DateTimeInput',
            properties: {
                label: data.properties.label,
                required: data.properties.required,
                showLabel: data.properties.showLabel,
                placeholder: data.properties.placeholder,
                allowPastDates: data.properties.allowPastDates,
                adminOnly: data.properties.adminOnly
            }
        })
        this.elementType = 'DateTimeInput'
    }
}

export class TimeInputElementClass
    extends BaseElementClass<TimeInputElementProperties>
    implements TimeInputElement {
    elementType: 'TimeInput'
    constructor(data: TimeInputElement) {
        super({
            ...data,
            elementType: 'TimeInput',
            properties: {
                label: data.properties.label,
                required: data.properties.required,
                showLabel: data.properties.showLabel,
                placeholder: data.properties.placeholder,
                adminOnly: data.properties.adminOnly
            }
        })
        this.elementType = 'TimeInput'
    }
}

export class RatingInputElementClass
    extends BaseElementClass<RatingInputElementProperties>
    implements RatingInputElement {
    elementType: 'RatingInput'
    constructor(data: RatingInputElement) {
        super({
            ...data,
            elementType: 'RatingInput',
            properties: {
                label: data.properties.label,
                required: data.properties.required,
                showLabel: data.properties.showLabel,
                maxRating: data.properties.maxRating,
                initialRating: data.properties.initialRating,
                adminOnly: data.properties.adminOnly
            }
        })
        this.elementType = 'RatingInput'
    }
}

export class SignatureInputElementClass
    extends BaseElementClass<SignatureInputElementProperties>
    implements SignatureInputElement {
    elementType: 'SignatureInput'
    constructor(data: SignatureInputElement) {
        super({
            ...data,
            elementType: 'SignatureInput',
            properties: {
                label: data.properties.label,
                required: data.properties.required,
                showLabel: data.properties.showLabel,
                adminOnly: data.properties.adminOnly
            }
        })
        this.elementType = 'SignatureInput'
    }
}

export class UrlInputElementClass
    extends BaseElementClass<UrlInputElementProperties>
    implements UrlInputElement {
    elementType: 'UrlInput'
    constructor(data: UrlInputElement) {
        super({
            ...data,
            elementType: 'UrlInput',
            properties: {
                label: data.properties.label,
                required: data.properties.required,
                showLabel: data.properties.showLabel,
                placeholder: data.properties.placeholder,
                adminOnly: data.properties.adminOnly
            }
        })
        this.elementType = 'UrlInput'
    }
}

export class TableInputElementClass
    extends BaseElementClass<TableInputElementProperties>
    implements TableInputElement {
    elementType: 'TableInput'
    constructor(data: TableInputElement) {
        super({
            ...data,
            elementType: 'TableInput',
            properties: {
                label: data.properties.label,
                rows: data.properties.rows,
                columns: data.properties.columns,
                showLabel: data.properties.showLabel,
                data: data.properties.data,
                adminOnly: data.properties.adminOnly
            }
        })
        this.elementType = 'TableInput'
    }
}

export type DocumentElement =
    | DropdownElement
    | TextBlockElement
    | TextInputElement
    | MultiLineTextInputElement
    | PhoneNumberInputElement
    | YesNoInputElement
    | DateInputElement
    | SsnInputElement
    | EmailInputElement
    | AddressInputElement
    | CurrencyInputElement
    | DateTimeInputElement
    | TimeInputElement
    | RatingInputElement
    | SignatureInputElement
    | UrlInputElement
    | TableInputElement

export type DocumentElementClass =
    | DropdownElementClass
    | TextBlockElementClass
    | TextInputElementClass
    | MultiLineTextInputElementClass
    | PhoneNumberInputElementClass
    | YesNoInputElementClass
    | DateInputElementClass
    | SsnInputElementClass
    | EmailInputElementClass
    | AddressInputElementClass
    | CurrencyInputElementClass
    | DateTimeInputElementClass
    | TimeInputElementClass
    | RatingInputElementClass
    | SignatureInputElementClass
    | UrlInputElementClass
    | TableInputElementClass
