import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "store/store";
import React, {useEffect, useState} from "react";
import TextInput from "components/inputs/TextInput";
import SubmitButton from "components/SubmitButton";
import {setIsEditing} from "features/credential/credentialSlice";
import DescriptionInput from "components/inputs/DescriptionInput";
import {UserTraining} from "types/userTraining";
import DateInput from "components/inputs/DateInput";
import FileInput from "components/inputs/FileInput";
import {TrainingThunks} from "../training/trainingThunks";
import {UserTrainingThunks} from "./userTrainingThunks";
import XIcon from "assets/images/icons/XIcon";
import FileDetailBox from "components/FileDetailBox";
import GroupInput from "../../components/inputs/GroupInput";

interface UserTrainingFormProps {
  id?: string;
  closeDrawer: () => void;
  isDrawerOpen: boolean;
}

const UserTrainingForm: React.FC<UserTrainingFormProps> = ({
  closeDrawer,
  isDrawerOpen,
}) => {
  const userTraining = useSelector(
    (state: RootState) => state.userTraining.userTraining,
  );
  const dispatch = useDispatch<AppDispatch>();
  const [updatedUserTraining, setUpdatedUserTraining] = useState(userTraining);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleFileChange = (files: FileList | null) => {
    if (files && files.length > 0) {
      const attachment = files[0];
      setUploadedFile(attachment);
      setUpdatedUserTraining((prevState: UserTraining) => ({
        ...prevState,
        attachment: attachment,
      }));
      setIsLoadingFile(true);
    }
  };

  useEffect(() => {
    if (!isLoadingFile) return;

    const loadingDuration = 2000;
    const timer = setTimeout(() => {
      setIsLoadingFile(false);
    }, loadingDuration);

    return () => clearTimeout(timer);
  }, [isLoadingFile]);

  useEffect(() => {
    const isFormValid =
      updatedUserTraining?.licensingAuthority?.trim() &&
      (uploadedFile !== null || userTraining?.attachmentUrl) &&
      !isLoadingFile;
    setIsSaveDisabled(!isFormValid);
  }, [updatedUserTraining, uploadedFile, isLoadingFile]);

  useEffect(() => {
    setUpdatedUserTraining(userTraining);
    setIsSaveDisabled(!userTraining?.name);
  }, [userTraining]);

  useEffect(() => {
    dispatch(TrainingThunks.index());
  }, [dispatch]);

  useEffect(() => {
    if (!isDrawerOpen) handleClose();
  }, [isDrawerOpen]);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const {id, value} = e.target;
    setUpdatedUserTraining({...updatedUserTraining, [id]: value});
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setIsSaveDisabled(true);
    if (userTraining?.id) {
      await dispatch(
        UserTrainingThunks.update(new UserTraining(updatedUserTraining)),
      ).then(() => closeDrawer());
    } else {
      await dispatch(
        UserTrainingThunks.create(new UserTraining(updatedUserTraining)),
      ).then(() => closeDrawer());
    }
  };

  const handleClose = () => {
    dispatch(setIsEditing(false));
    setUpdatedUserTraining({
      id: "",
      name: "",
      licensingAuthority: "",
      expirationDate: "",
      remindUserAt: "",
      notes: "",
      userId: "",
    });

    setUploadedFile(null);
    setIsSaveDisabled(true);
    setIsSubmitting(false);
  };

  const handleDelete = () => {
    setUploadedFile(null);
    setUpdatedUserTraining((prevState: UserTraining) => ({
      ...prevState,
      attachment: null,
      deleteAttachment: true,
      attachmentUrl: null,
    }));
  };

  return (
    <div className="flex flex-col h-screen">
      <div className="flex-1 flex flex-col">
        <div className="h-auto p-6 bg-sky-50 border-b border-sky-200 flex justify-between items-start gap-2 w-full">
          <div className="flex flex-col justify-start items-start gap-1">
            <div className="text-lg font-semibold tracking-normal text-cyan-800">
              {`Upload ${userTraining?.name}`}
            </div>

            <div className="text-sm font-light tracking-normal leading-5 text-zinc-400">
              {`Add an employees required training so you can keep track of their status and remain compliant.`}
            </div>
          </div>
          <button
            onClick={closeDrawer}
            className="text-gray-500 hover:text-gray-700 focus:outline-none pt-2">
            <XIcon />
          </button>
        </div>
        <div className="h-full px-6 pb-6 ">
          <div className="mt-5">
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col space-y-6 items-center mt-7">
                <div className="w-full">
                  <FileInput
                    onFileChange={handleFileChange}
                    label="Drop your files here or"
                    labelBlue="browse"
                    subtitle="Maximum size: 50MB"
                    disabled={isLoadingFile}
                  />
                </div>
                {uploadedFile && (
                  <div className="w-full">
                    <FileDetailBox
                      uploadedFile={uploadedFile}
                      handleDelete={handleDelete}
                      isLoading={isLoadingFile}
                      loadingTime={2000}
                    />
                  </div>
                )}
                {userTraining?.attachment?.url &&
                  uploadedFile === null &&
                  updatedUserTraining.attachment?.url !== null && (
                    <div className="w-full">
                      <FileDetailBox
                        uploadedFile={userTraining?.attachment}
                        handleDelete={handleDelete}
                        fileCurrent={true}
                      />
                    </div>
                  )}
                {userTraining?.previousAttachments &&
                  userTraining?.previousAttachments.map(
                    (file: any, index: number) => (
                      <div className="w-full" key={index}>
                        <FileDetailBox
                          uploadedFile={file}
                          handleDelete={handleDelete}
                          fileCurrent={false}
                        />
                      </div>
                    ),
                  )}
                <div className="w-full">
                  <TextInput
                    id="licensingAuthority"
                    type="text"
                    label="Licensing Authority"
                    value={updatedUserTraining?.licensingAuthority}
                    placeholder=""
                    onChange={handleChange}
                  />
                </div>
                <div className="w-full">
                  <DateInput
                    id="completedAt"
                    label="Completion date"
                    type="date"
                    value={updatedUserTraining?.completedAt}
                    onChange={handleChange}
                    placeholder="MM/DD/YYYY"
                    allowPastDates={true}
                  />
                </div>
                <div className="flex justify-between gap-6 w-full ">
                  <DateInput
                    id="expirationDate"
                    label="Expiration date"
                    type="date"
                    value={updatedUserTraining?.expirationDate}
                    onChange={handleChange}
                    placeholder="MM/DD/YYYY"
                    optional="Optional"
                  />
                  <div className="flex justify-end w-40">
                    <GroupInput
                      id="userReminderDays"
                      type="number"
                      label="Remind"
                      value={updatedUserTraining?.userReminderDays}
                      onChange={handleChange}
                      addon="days before"
                    />
                  </div>
                </div>
                <div className="w-full">
                  <DescriptionInput
                    id="notes"
                    type="text"
                    label="Notes"
                    value={updatedUserTraining?.notes}
                    placeholder=""
                    onChange={handleChange}
                    optional="optional"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <footer className="flex-shrink-0 px-6 pb-4 pt-2 flex justify-end gap-5">
        <div className="border-t border-slate-200 w-full">
          <div className="flex w-full justify-center items-center gap-x-2 pb-4">
            <SubmitButton
              onClick={handleSubmit}
              label={`${isSubmitting ? "Uploading" : "Upload"}`}
              disabled={isSaveDisabled || isSubmitting}
            />
          </div>
          <div className="flex w-full justify-center items-center gap-x-2 pb-4">
            <button
              type="submit"
              onClick={closeDrawer}
              className="self-center text-sm font-light tracking-normal leading-5 text-center text-zinc-400 hover:text-cyan-400">
              Never mind
            </button>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default UserTrainingForm;
