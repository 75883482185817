import React, {useState} from 'react';
import BrowseFileIcon from "assets/images/icons/BrowseFileIcon";

interface FileInputProps {
    label?: string,
    onFileChange: (files: globalThis.FileList | null) => void;
    labelBlue?: string,
    subtitle?: string,
    disabled?: boolean,
    noBorder?: boolean,

}

const FileInput: React.FC<FileInputProps> = ({
                                                 label = 'Drop your files here or',
                                                 onFileChange,
                                                 labelBlue,
                                                 subtitle,
                                                 disabled = false,
                                                 noBorder = false,
                                             }) => {
    const [dragging, setDragging] = useState(false);

    const handleDragEnter = (e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (!disabled) setDragging(true);
    };

    const handleDragLeave = (e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (!disabled) setDragging(false);
    };

    const handleDrop = (e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (!disabled) {
            setDragging(false);
            if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
                onFileChange(e.dataTransfer.files as globalThis.FileList);
            }
        }
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (!disabled && files && files.length > 0) {
            onFileChange(files);
            e.target.value = '';
        }
    };

    return (
        <div
            className={`h-[208.75px] w-full flex-col justify-start items-center inline-flex ${disabled ? 'cursor-not-allowed' : ''}`}>
            <div
                className={`self-stretch h-[208.75px] pt-12 rounded-xl border-dashed border 
                ${noBorder? 'border-transparent' : ''}
                ${dragging ? 'border-cyan-400' : 'border-slate-200'
                } flex-col justify-start items-center flex ${disabled ? 'bg-slate-100 text-gray-400 border-gray-300' : 'bg-white'}`}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                onDragOver={(e) => e.preventDefault()}
            >
                <div className={`w-[70px] h-[44.75px] relative ${disabled ? 'text-gray-400 cursor-not-allowed' : 'text-cyan-400'}`}>
                    <BrowseFileIcon/>
                </div>
                <div className="flex-col justify-start items-center mt-2 flex ">
                    <div className="justify-center items-start gap-[5px] inline-flex">
                        <div
                            className={`text-slate-800 text-base font-medium leading-normal tracking-tight ${disabled ? 'text-gray-400' : ''}`}>
                            {label}
                        </div>
                        <div className="justify-start items-start flex">
                            <label
                                className={`text-center text-cyan-400 text-base font-semibold cursor-pointer ${disabled ? 'text-gray-400 cursor-not-allowed' : ''}`}>
                                {labelBlue}
                                <input
                                    type="file"
                                    className="hidden"
                                    onChange={handleFileChange}
                                    disabled={disabled}
                                />
                            </label>
                        </div>
                    </div>
                </div>
                <div
                    className={`text-center text-gray-400 text-sm font-medium font-['Inter'] leading-tight tracking-tight ${disabled ? 'text-gray-400' : ''}`}>
                    {subtitle}
                </div>
            </div>
        </div>
    );
};

export default FileInput;
