import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const DrawerSkeleton: React.FC = () => {
  return (
    <div className="flex flex-col h-screen">
      <div className="flex-1 flex flex-col">
        <div className="h-[130px] px-6 pt-6 pb-10 bg-sky-50 border-b border-sky-200 flex justify-between items-start gap-2 w-full">
          <div className="flex flex-col justify-start items-start gap-1">
            <Skeleton width={200} height={24} />
            <Skeleton width={300} height={16} />
          </div>
          <Skeleton circle={true} height={24} width={24} />
        </div>

        <div className="h-full px-6 pb-6 ">
          <div className="mt-5">
            <form>
              <div className="flex flex-col space-y-6 items-center mt-7">
                <div className="w-full">
                  <Skeleton height={40} />
                </div>
                <div className="w-full">
                  <Skeleton height={80} />
                  <div className="w-full mt-6">
                    <Skeleton height={40} />
                  </div>

                  <div className="space-y-6 items-center mt-7">
                    <Skeleton width={150} height={24} />
                    <Skeleton width={100} height={24} />
                    <Skeleton width={150} height={24} />
                    <Skeleton height={40} />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <footer className="flex-shrink-0 px-6 pb-4 pt-2 flex justify-end gap-5">
        <div className="border-t border-slate-200 w-full">
          <div className="flex w-full justify-center items-center gap-x-2 pb-4">
            <Skeleton height={40} width={200} />
          </div>
          <div className="flex w-full justify-center items-center gap-x-2 pb-4">
            <Skeleton height={20} width={100} />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default DrawerSkeleton;
