import React from "react";


const SearchIcon = () => (
    <svg width="20" height="20" viewBox="0 0 14 14" fill="none"  xmlns="http://www.w3.org/2000/svg">
        <g id="search">
            <path id="Vector"
                  d="M6.41667 11.0833C8.994 11.0833 11.0833 8.994 11.0833 6.41667C11.0833 3.83934 8.994 1.75 6.41667 1.75C3.83934 1.75 1.75 3.83934 1.75 6.41667C1.75 8.994 3.83934 11.0833 6.41667 11.0833Z"
                  stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
            <path id="Vector_2" d="M12.25 12.25L9.74167 9.7417" stroke="currentColor" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </g>
    </svg>
);
export default SearchIcon;
