import React, {useEffect, useState} from 'react';
import XIcon from "assets/images/icons/XIcon";
import PasswordInput from "components/inputs/PasswordInput";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "store/store";
import {validatePassword} from "utils/validators";
import usePinInput from "hooks/usePinInput";
import SubmitButton from "components/SubmitButton";
import {UserThunks} from "./userThunks";
import {showToast} from "components/ToastContainer";

interface UpdatePinProps {
    onClose: () => void;

}

const UpdatePin: React.FC<UpdatePinProps> = ({ onClose }) => {
    const dispatch = useDispatch<AppDispatch>();
    const user = useSelector((state: RootState) => state.auth.user);
    const [password, setPassword] = useState('');
    const [, setPasswordError] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const {
        firstInputRef: newPinFirstInputRef,
        pin: newPin,
        setPin: setNewPin,
        error: newPinError,
        handlePinChange: handleNewPinChange,
        handleKeyDown: handleNewPinKeyDown,
        handlePaste: handleNewPinPaste,
    } = usePinInput({ initialPinLength: 4, pinPrefix: "new-"});

    const {
        firstInputRef: confirmPinFirstInputRef,
        pin: confirmPin,
        setPin: setConfirmPin,
        error: confirmPinError,
        handlePinChange: handleConfirmPinChange,
        handleKeyDown: handleConfirmPinKeyDown,
        handlePaste: handleConfirmPinPaste,
    } = usePinInput({ initialPinLength: 4, pinPrefix: "confirm-" });

    const [isSaveDisabled, setIsSaveDisabled] = useState(true);

    useEffect(() => {
        const fullNewPin = newPin.join("");
        const fullConfirmPin = confirmPin.join("");
        const isPinsFilled = fullNewPin.length === 4 && fullConfirmPin.length === 4;
        const isPinsMatch = fullNewPin === fullConfirmPin;
        const isPasswordValid = validatePassword(password);

        setIsSaveDisabled(!(isPinsFilled && isPinsMatch && isPasswordValid));
    }, [newPin, confirmPin, password]);

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setPassword(value);

        if (validatePassword(value)) {
            setPasswordError('');
        } else {
            setPasswordError('Invalid password');
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const fullNewPin = newPin.join("");
        const fullConfirmPin = confirmPin.join("");

        if (fullNewPin !== fullConfirmPin) {
            showToast("PIN codes do not match.", "error");
            return;
        }

        if (!password) {
            showToast("Please enter your password.", "error");
            return;
        }

        try {
            await dispatch(
                UserThunks.changePIN({
                    id: user?.id || "",
                    password: password,
                    newPIN: fullNewPin,
                })
            ).unwrap();
            onClose();
        } catch (error) {
        }
    };

    const handleClose = () => {
        setPassword('');
        setPasswordError('');
        setShowPassword(false);
        setNewPin(['', '', '', '']);
        setConfirmPin(['', '', '', '']);

        onClose();

    };
    return (
        <div className="flex flex-col h-screen">
            <div className="flex-1 flex flex-col">
                <div
                    className="h-auto p-6 bg-sky-50 border-b border-sky-200 flex justify-between items-start gap-2 w-full">
                    <div className="flex flex-col justify-start items-start gap-1">
                        <div className="text-lg font-semibold tracking-normal text-cyan-800">
                            PIN
                        </div>

                        <div className="text-sm font-light tracking-normal leading-5 text-zinc-400">
                            Update your user PIN
                        </div>
                    </div>
                    <button
                        onClick={handleClose}
                        className="text-gray-500 hover:text-gray-700 focus:outline-none pt-2">
                        <XIcon/>
                    </button>
                </div>
                <div className="h-full px-6 pb-6 ">
                    <div className="mt-5">
                        <form onSubmit={handleSubmit}>
                            <div className="self-stretch w-full">
                                <div className="flex flex-col space-y-6">
                                    <div className="w-full">
                                        <PasswordInput
                                            label=" Current Password"
                                            password={password}
                                            onPasswordChange={handlePasswordChange}
                                            passwordError={false}
                                            showPassword={showPassword}
                                            onToggleShowPassword={() => setShowPassword(!showPassword)}
                                            inputId="Password"
                                        />
                                    </div>
                                    <div className="w-full">
                                        <div
                                            className="block grow shrink basis-0 text-slate-800 text-sm font-semibold font-['Inter'] dark:text-white">
                                            New PIN
                                        </div>
                                        <div className="w-80 flex  py-2.5 flex-cols-4 justify-start gap-3">
                                            {newPin.map((data, index) => (
                                                <input
                                                    data-hs-pin-input
                                                    key={index}
                                                    id={`new-pin-input-${index}`}
                                                    type="text"
                                                    maxLength={1}
                                                    ref={index === 0 ? newPinFirstInputRef : null}
                                                    className={` w-9 h-10 border border-slate-200 bg-white text-center rounded-lg text-sm placeholder:text-gray-400 outline-cyan-400 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600 ${newPinError ? "border-red-500" : "border-slate-200"}`}
                                                    value={data}
                                                    onChange={(e) => handleNewPinChange(e.target.value, index)}
                                                    onKeyDown={(e) => handleNewPinKeyDown(e, index)}
                                                    onPaste={handleNewPinPaste}
                                                    onFocus={(e) => e.target.select()}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                    <div className="w-full">
                                        <div
                                            className="block grow shrink basis-0 text-slate-800 text-sm font-semibold font-['Inter'] dark:text-white">
                                            Confirm new PIN
                                        </div>
                                        <div className="w-80 py-2.5  flex flex-cols-4 justify-start gap-3">
                                            {confirmPin.map((data, index) => (
                                                <input
                                                    data-hs-pin-input
                                                    key={index}
                                                    id={`confirm-pin-input-${index}`}
                                                    type="text"
                                                    maxLength={1}
                                                    ref={index === 0 ? confirmPinFirstInputRef : null}
                                                    className={` w-9 h-10 border border-slate-200 bg-white text-center rounded-lg text-sm placeholder:text-gray-400 outline-cyan-400 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600 ${confirmPinError ? "border-red-500" : "border-slate-200"}`}
                                                    value={data}
                                                    onChange={(e) => handleConfirmPinChange(e.target.value, index)}
                                                    onKeyDown={(e) => handleConfirmPinKeyDown(e, index)}
                                                    onPaste={handleConfirmPinPaste}
                                                    onFocus={(e) => e.target.select()}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <footer className="flex-shrink-0 px-6 pb-4 pt-2 flex justify-end gap-5">
                <div className="border-t border-slate-200 w-full">
                    <div className="flex w-full justify-center items-center gap-x-2 pb-4">
                        <SubmitButton
                            onClick={handleSubmit}
                            label="Update PIN"
                            disabled={isSaveDisabled}
                        />
                    </div>
                    <div className="flex w-full justify-center items-center gap-x-2 pb-4">
                        <button
                            type="button"
                            onClick={handleClose}
                            className="self-center text-sm font-light tracking-normal leading-5 text-center text-zinc-400 hover:text-cyan-400"
                        >
                            Never mind
                        </button>
                    </div>
                </div>
            </footer>
        </div>

    );
};

export default UpdatePin;
