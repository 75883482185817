import React, {useEffect, useState} from 'react';
import {UploadRequest} from 'types/uploadRequest';
import {UploadRequestService} from 'services/uploadRequestService';
import {useParams} from 'react-router-dom';
import FileDetailBox from 'components/FileDetailBox';
import SubmitButton from 'components/SubmitButton';
import LogoNewHope from 'assets/images/LogoNewHope.png';
import PlusIcon from 'assets/images/icons/PlusIcon';
import BrowseFileIcon from 'assets/images/icons/BrowseFileIcon';
import DescriptionInput from 'components/inputs/DescriptionInput';

const UploadRequestPage: React.FC = () => {
  const [uploadRequest, setUploadRequest] = useState<UploadRequest>();
  const params = useParams();
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [step, setStep] = useState<number>(0);

  const onFileChange = (files: FileList | null) => {
    if (files && files.length > 0) {
      const attachment = files[0];
      setUploadedFile(attachment);
      setIsLoadingFile(true);
    }
    setStep(1);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      onFileChange(files);
      e.target.value = '';
    }
  };
  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const {id, value} = e.target;
    const updatedUploadRequest = new UploadRequest({
      ...uploadRequest,
      [id]: value,
    });
    setUploadRequest(updatedUploadRequest);
  };

  useEffect(() => {
    UploadRequestService.show(params?.token!).then(response => {
      setUploadRequest(response);
    });
  }, []);

  useEffect(() => {
    if (!isLoadingFile) return;

    const loadingDuration = 2000;
    const timer = setTimeout(() => {
      setIsLoadingFile(false);
      setIsSaveDisabled(false);
    }, loadingDuration);

    return () => clearTimeout(timer);
  }, [isLoadingFile]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (uploadedFile) {
      setIsSubmitting(true);
      const formData = {
        attachment: uploadedFile,
        token: params?.token,
        notes: uploadRequest?.notes,
      };
      await UploadRequestService.upload(formData).then(() => {
        setIsSubmitting(false);
        setStep(2);
      });
    }
  };

  const handleDelete = () => {
    setUploadedFile(null);
    setIsSaveDisabled(true);
    setStep(0);
  };

  const renderHeader = () => (
    <div className="flex justify-center w-full">
      <img className="m-8 w-[119px]" src={LogoNewHope} alt="Logo" />
    </div>
  );

  const renderIconSection = (title: string, subtitle: React.ReactNode) => (
    <div className="flex flex-col items-center h-[208.75px] pt-14 rounded-xl">
      <div className="w-[70px] h-[44.75px] relative text-cyan-400">
        <BrowseFileIcon />
      </div>
      <div className="mt-2">
        <div className="text-slate-600 text-xl font-medium leading-normal tracking-tight">
          {title}
        </div>
      </div>
      <div className="w-full sm:w-72 mt-1 text-center text-slate-400 text-sm font-normal font-['Inter'] leading-tight tracking-tight">
        {subtitle}
      </div>
    </div>
  );

  return (
    <div className="flex flex-col h-screen bg-slate-50 ">
      {step === 0 && (
        <>
          <div className="flex-1 flex flex-col">
            {renderHeader()}
            {renderIconSection(
              'Upload missing document',
              <>
                Please upload a copy of your{' '}
                <span className="font-bold">{uploadRequest?.name}</span>{' '}
                clicking the upload button below.
              </>,
            )}
          </div>
          <footer className="flex-shrink-0 px-6 pb-4 pt-2 flex justify-end gap-5">
            <div className="flex w-full justify-center items-center gap-x-2 pb-4">
              <label className="w-16 h-16 px-6 py-3 rounded-full bg-cyan-400 text-white font-semibold text-sm  justify-center justify-items-center">
                <input
                  type="file"
                  className="hidden"
                  onChange={handleFileChange}
                />
                <div className="justify-items-center text-white pt-3">
                  <PlusIcon />
                </div>
              </label>
            </div>
          </footer>
        </>
      )}
      {step === 1 && (
        <>
          <div className="flex-1 flex flex-col">
            {renderHeader()}
            {renderIconSection(
              'Upload missing document',
              <>
                Please upload a copy of your{' '}
                <span className="font-bold">{uploadRequest?.name}</span>{' '}
                clicking the upload button below.
              </>,
            )}
            <div className="h-full px-6 pb-6 ">
              <form onSubmit={handleSubmit}>
                <div className="flex flex-col space-y-6 items-center mt-8">
                  {uploadedFile && (
                    <div className="w-80 space-y-6">
                      <FileDetailBox
                        uploadedFile={uploadedFile}
                        handleDelete={handleDelete}
                        isLoading={isLoadingFile}
                        loadingTime={2000}
                      />
                      <DescriptionInput
                        id="notes"
                        type="text"
                        label="Notes"
                        value={uploadRequest?.notes || ''}
                        placeholder=""
                        onChange={handleChange}
                        optional="Optional"
                      />
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
          <footer className="flex-shrink-0 px-6 pb-4 pt-2 flex justify-center gap-5">
            <div className="flex w-80 justify-center items-center gap-x-2 pb-4">
              <SubmitButton
                onClick={handleSubmit}
                label={`${isSubmitting ? 'Uploading' : 'Upload'}`}
                disabled={isSaveDisabled || isSubmitting}
              />
            </div>
          </footer>
        </>
      )}
      {step === 2 && (
        <>
          <div className="flex-1 flex flex-col">
            {renderHeader()}
            {renderIconSection(
              'Success!',
              'Thank you. Your document has been successfully uploaded.',
            )}
          </div>
        </>
      )}
    </div>
  );
};
export default UploadRequestPage;
