import React from "react";
import SignatureCanvas from "react-signature-canvas";

interface SignatureInputProps {
    signatureCanvasRef: React.RefObject<SignatureCanvas>,
    error?: string,
    onEnd?: () => void
    width?: number
    height?: number
    disabled?: boolean
}

const SignatureInput: React.FC<SignatureInputProps> = ({
  signatureCanvasRef,
  disabled = false,
  error,
  width = 320,
  height = 130,
}) => {
  return (
    <div className="flex flex-col items-start gap-[0.625rem]">
      <label
        htmlFor=""
        className="w-80 block grow shrink basis-0 text-slate-800 text-sm font-semibold font-['Inter'] dark:text-white"></label>
      <div
        className={`flex w-full h-[147px] py-2.5 px-3 focus:outline-cyan-400 border border-gray-200 rounded-lg text-sm font-light placeholder:text-gray-400
                            ${disabled ? "pointer-events-none" : ""}
                            dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600`}>
        <div className="flex justify-center items-center w-full h-full">
          <SignatureCanvas
            ref={signatureCanvasRef}
            penColor="black"
            canvasProps={{width, height, className: "signature-canvas"}}
          />
        </div>
      </div>
      {error && <div className="text-red-500 text-sm">{error}</div>}
    </div>
  );
};

export default SignatureInput;
