import {
    createSlice,
    PayloadAction,
    AnyAction,
    SliceCaseReducers,
    ValidateSliceCaseReducers,
} from '@reduxjs/toolkit';
import { showToast } from 'components/ToastContainer';

interface EntityState<_EntityType> {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    entityStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    isEditing: boolean;
    activeTab?: string;
    pagy?: any;
    [key: string]: any; // Allow dynamic keys for entity and entities
}

interface CreateEntitySliceOptions<EntityType> {
    name: string;
    initialState?: Partial<EntityState<EntityType>>;
    reducers?: SliceCaseReducers<EntityState<EntityType>>;
    extraReducers?: Record<string, (state: EntityState<EntityType>, action: AnyAction) => void>;
    thunks: any;
    entityKey?: string;
    entitiesKey?: string;
}

export function createEntitySlice<EntityType>({
                                                  name,
                                                  initialState,
                                                  reducers = {} as SliceCaseReducers<EntityState<EntityType>>,
                                                  extraReducers = {},
                                                  thunks,
                                                  entityKey = 'entity',
                                                  entitiesKey = 'entities',
                                              }: CreateEntitySliceOptions<EntityType>) {
    const defaultInitialState: EntityState<EntityType> = {
        status: 'idle',
        entityStatus: 'idle',
        error: null,
        [entitiesKey]: [],
        [entityKey]: null,
        isEditing: false,
        activeTab: 'All',
        pagy: {},
        ...initialState,
    };

    const overriddenActionTypes = new Set(Object.keys(extraReducers));

    const slice = createSlice({
        name,
        initialState: defaultInitialState,
        reducers: {
            setEntity: (state, action: PayloadAction<EntityType | null>) => {
                state[entityKey] = action.payload;
            },
            setIsEditing: (state, action: PayloadAction<boolean>) => {
                state.isEditing = action.payload;
            },
            setActiveTab: (state, action: PayloadAction<string>) => {
                state.activeTab = action.payload;
            },
            ...reducers,
        } as ValidateSliceCaseReducers<
            EntityState<EntityType>,
            SliceCaseReducers<EntityState<EntityType>>
        >,
        extraReducers: (builder) => {
            // Helper function to capitalize entity name for messages
            const capitalizeName = (str: string) =>
                str.charAt(0).toUpperCase() + str.slice(1);

            // Default action handlers
            const defaultActionHandlers: Record<string, (state: EntityState<EntityType>, action: AnyAction) => void> = {
                // Create
                [thunks.create.pending.type]: (state) => {
                    state.status = 'loading';
                },
                [thunks.create.fulfilled.type]: (state, action: AnyAction) => {
                    state.status = 'succeeded';
                    state[entityKey] = action.payload;
                    state[entitiesKey] = [action.payload, ...state[entitiesKey]];
                    showToast(`${capitalizeName(name)} created successfully`, 'success');
                },
                [thunks.create.rejected.type]: (state, action) => {
                    state.status = 'failed';
                    state.error = action.error.message || null;
                },
                // Update
                [thunks.update.pending.type]: (state) => {
                    state.status = 'loading';
                },
                [thunks.update.fulfilled.type]: (state, action: AnyAction) => {
                    state.status = 'succeeded';
                    const updatedEntity = action.payload;
                    state[entityKey] = updatedEntity;

                    const index = state[entitiesKey].findIndex(
                        (item: any) => item.id === updatedEntity.id
                    );
                    if (index !== -1) {
                        state[entitiesKey][index] = updatedEntity;
                    }
                    showToast(`${capitalizeName(name)} updated successfully`, 'success');
                },
                [thunks.update.rejected.type]: (state, action) => {
                    state.status = 'failed';
                    state.error = action.error.message || null;
                },
                // Show
                [thunks.show.pending.type]: (state) => {
                    state.entityStatus = 'loading';
                },
                [thunks.show.fulfilled.type]: (state, action: AnyAction) => {
                    state.entityStatus = 'succeeded';
                    state[entityKey] = action.payload;
                },
                [thunks.show.rejected.type]: (state, action) => {
                    state.entityStatus = 'failed';
                    state.error = action.error.message || null;
                },
                // Index
                [thunks.index.pending.type]: (state) => {
                    state.status = 'loading';
                },
                [thunks.index.fulfilled.type]: (state, action: AnyAction) => {
                    state.status = 'succeeded';
                    state[entitiesKey] = action.payload.data;
                    state.pagy = action.payload.pagy;
                },
                [thunks.index.rejected.type]: (state, action) => {
                    state.status = 'failed';
                    state.error = action.error.message || null;
                },
                // Delete
                [thunks.delete.pending.type]: (state) => {
                    state.status = 'loading';
                },
                [thunks.delete.fulfilled.type]: (state, action: AnyAction) => {
                    state.status = 'succeeded';
                    state[entitiesKey] = state[entitiesKey].filter(
                        (item: any) => item.id !== action.payload
                    );
                    showToast(`${capitalizeName(name)} deleted successfully`, 'success');
                },
                [thunks.delete.rejected.type]: (state, action) => {
                    state.status = 'failed';
                    state.error = action.error.message || null;
                },
            };

            // Merge default handlers, skipping overridden ones
            for (const [actionType, reducer] of Object.entries(defaultActionHandlers)) {
                if (!overriddenActionTypes.has(actionType)) {
                    builder.addCase(actionType, reducer);
                }
            }

            // Handle activate/deactivate if they exist
            if (thunks.activate) {
                const activateType = thunks.activate.fulfilled.type;
                if (!overriddenActionTypes.has(activateType)) {
                    builder.addCase(thunks.activate.fulfilled, (state, action: AnyAction) => {
                        state.status = 'succeeded';
                        const updatedEntity = action.payload;
                        state[entityKey] = updatedEntity;

                        const index = state[entitiesKey].findIndex(
                            (item: any) => item.id === updatedEntity.id
                        );
                        if (index !== -1) {
                            state[entitiesKey][index] = updatedEntity;
                        }
                        showToast(`Activated successfully`, 'success');
                    });
                }
            }

            if (thunks.deactivate) {
                const deactivateType = thunks.deactivate.fulfilled.type;
                if (!overriddenActionTypes.has(deactivateType)) {
                    builder.addCase(thunks.deactivate.fulfilled, (state, action: AnyAction) => {
                        state.status = 'succeeded';
                        const updatedEntity = action.payload;
                        state[entityKey] = updatedEntity;

                        const index = state[entitiesKey].findIndex(
                            (item: any) => item.id === updatedEntity.id
                        );
                        if (index !== -1) {
                            state[entitiesKey][index] = updatedEntity;
                        }
                        showToast(`Deactivated successfully`, 'success');
                    });
                }
            }

            // Add the extraReducers provided by the user
            for (const [actionType, reducer] of Object.entries(extraReducers)) {
                builder.addCase(actionType, reducer);
            }
        },
    });

    return slice;
}
