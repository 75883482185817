export interface UploadRequestInterface {
    id?: string;
    name?: string;
    token?: string;
    ownerType: string,
    ownerId: string,
    ownerField: string,
    notes?: string;
}

export class UploadRequest implements UploadRequestInterface {
    id?: string;
    name?: string;
    token?: string;
    ownerType: string;
    ownerId: string;
    ownerField: string;
    notes?: string;


    constructor(data: Partial<UploadRequestInterface>) {
        this.id = data.id!;
        this.name = data.name!;
        this.token = data.token!;
        this.ownerType = data.ownerType!;
        this.ownerId = data.ownerId!;
        this.ownerField = data.ownerField!;
        this.notes = data.notes;
    }

    toJson(): UploadRequestInterface {
        return {
            ownerId: this.ownerId,
            ownerType: this.ownerType,
            ownerField: this.ownerField,
            notes: this.notes,
        };
    }
}