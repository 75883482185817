import React, {useState} from 'react';
import {setActiveTab} from "features/userHrDocument/userHrDocumentSlice";
import Table from "../../components/table/Table";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store/store";
import {SearchType} from "../../types/search";
import {setIsEditing} from "../hrDocument/hrDocumentSlice";
import TableHeader from "../../components/table/TableHeader";
import TableRow from "../../components/table/TableRow";
import TableCell from "../../components/table/TableCell";
import CheckCircleIcon from "../../assets/images/icons/CheckCircleIcon";
import XCircleIcon from "../../assets/images/icons/XCircleIcon";
import DotsMenu from "../../components/DotsMenu";
import {useNavigate} from "react-router-dom";
import useAttachmentModal from "../../hooks/useAttachmentModal";
import {UserHrDocument} from "../../types/userHrDocument";
import {UserHrDocumentThunks} from "../userHrDocument/userHrDocumentThunks";
import Skeleton from "react-loading-skeleton";
import {JobRole} from "../../types/jobRole";
import CircleAlertIconIcon from "../../assets/images/icons/CircleAlertIconIcon";
import {UploadRequestService} from "../../services/uploadRequestService";
import {UploadRequest} from "../../types/uploadRequest";
import {showToast} from "../../components/ToastContainer";

const DashboardHrDocumentsTable: React.FC<{ openDrawer: () => void, optionsByTab: any }> = ({openDrawer, optionsByTab}) => {
    const userHrDocuments = useSelector((state: RootState) => state.userHrDocument.userHrDocuments);
    const loading = useSelector((state: RootState) => state.userHrDocument.status === 'loading');
    const dispatch = useDispatch<AppDispatch>();
    const pagy = useSelector((state: RootState) => state.userHrDocument.pagy);
    const activeTab = useSelector((state: RootState) => state.userHrDocument.activeTab);
    const navigate = useNavigate();



    const {AttachmentModal} = useAttachmentModal<UserHrDocument>({
        openDrawer,
        fetchItemThunk: UserHrDocumentThunks.show,

    });

    interface VisibleColumnsType {
        name: boolean;
        expDate: boolean;
        userName: boolean;
        status: boolean;
        jobRole: boolean;
        supervisor: boolean;
        email: boolean;
    }

    const [visibleColumns, setVisibleColumns] = useState({
        name: true,
        expDate: true,
        userName: true,
        status: true,
        jobRole: true,
        supervisor: true,
        email: true,
    });

    const searchByOptions = [
        {label: 'Document', value: 'name', operator: 'like'},
        {label: 'Employee', value: 'user.first_name', operator: 'like'},
    ];

    const columnOptions = [
        {label: 'Employee', value: 'userName', isVisible: visibleColumns.userName},
        {label: 'Document', value: 'name', isVisible: visibleColumns.name},
        {label: 'Exp Date', value: 'expDate', isVisible: visibleColumns.expDate},
        {label: 'Status', value: 'status', isVisible: visibleColumns.status},
        {label: 'Job Role', value: 'jobRole', isVisible: visibleColumns.jobRole},
        {label: 'Supervisor', value: 'supervisor', isVisible: visibleColumns.supervisor},
        {label: 'Email', value: 'email', isVisible: visibleColumns.email},
    ];

    const fetchData = async ({page, search, sortField, sortDirection, rowsPerPage}: {
        page: number;
        search: SearchType<{ [key: string]: { operator: string, value: string } }>;
        sortField: string;
        sortDirection: string;
        rowsPerPage: number
    }): Promise<void> => {
        if (activeTab === 'All') {
            search.where.status = {
                operator: 'in',
                valueArray: ['missing', 'expired', 'expiring_soon']
            };

        } else if (activeTab === 'Missing Documents') {
            search.where.status = {
                operator: '==',
                value: 'missing'
            }
        } else if (activeTab === 'Expiring Documents') {
            search.where.status = {
                operator: '==',
                value: 'expiring_soon'
            }
        } else if (activeTab === 'Expired Documents') {
            search.where.status = {
                operator: '==',
                value: 'expired'
            }
        } else {
            delete search.where.status;
        }
        search.where['user.hidden']= {
            operator: '==',
            value: 'false'
        }

        search.where['user.is_test'] = {
            operator: '!=',
            value: 'true'
        }
        await dispatch(UserHrDocumentThunks.index({page, search, sortField, sortDirection, rowsPerPage}));
    };
    const handleOpenUserPage = (id?: string) => async () => {
        navigate(`/hr/users/${id}`);
    }


    const handleColumnToggle = (column: string) => {
        if (column in visibleColumns) {
            setVisibleColumns((prev) => ({
                ...prev,
                [column]: !prev[column as keyof VisibleColumnsType],
            }));
        }
    };

    const handleEdit = async (id: string) => {
        await dispatch(UserHrDocumentThunks.show(id));
        dispatch(setIsEditing(true));
        openDrawer();
    }

    const handleRequestEmail = async (id: string) => {
        UploadRequestService.create({
            ownerType: "UserHrDocument",
            ownerId: id,
            ownerField: "attachment",
        } as UploadRequest).then(() => {
            showToast("Email sent successfully", "success");
        });
    };

    return (
      <>
        <Table
          tabs={[
            "All",
            "Missing Documents",
            "Expiring Documents",
            "Expired Documents",
          ]}
          activeTab={activeTab}
          fetchData={fetchData}
          setActiveTab={(tab: string) => dispatch(setActiveTab(tab))}
          pagy={pagy}
          loading={loading}
          recordsName={"Documents"}
          columns={true}
          blueButton={false}
          columnOptions={columnOptions}
          onColumnToggle={handleColumnToggle}
          searchByOptions={searchByOptions}
          showCounter={true}
          dateRange={true}
          optionsByTab={optionsByTab}>
          <thead>
            <tr>
              <th scope="col" className="px-3  pt-3.5  flex justify-center">
                <input
                  type="checkbox"
                  className="shrink-0 border-stone-300 rounded disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500
                                      dark:checked:border-green-500 dark:focus:ring-offset-neutral-800"
                />
              </th>
              {visibleColumns.userName && (
                <TableHeader label="Employee" sortBy="user.first_name" />
              )}
              {visibleColumns.name && (
                <TableHeader label="Document" sortBy="name" />
              )}
              {visibleColumns.expDate && (
                <TableHeader label="Exp Date" sortBy="expiresAt" />
              )}
              {visibleColumns.status && (
                <TableHeader label="Status" sortBy="status" />
              )}
              {visibleColumns.jobRole && <TableHeader label="Job Role" />}
              {visibleColumns.supervisor && <TableHeader label="Supervisor" />}
              {visibleColumns.email && (
                <TableHeader label="Email" sortBy="user.email" />
              )}

              <TableHeader label="" />
            </tr>
          </thead>
          <tbody>
            {userHrDocuments.length !== 0 &&
              userHrDocuments.map((userHrDocument: UserHrDocument) => {
                const userOptions = [
                  {
                    label: "Edit",
                    onClick: () => handleEdit(userHrDocument.id),
                    hoverClass: "hover:bg-cyan-100",
                  },

                  ...(userHrDocument.status === "Missing"
                    ? [
                        {
                          label: "Upload",
                          onClick: () => handleEdit(userHrDocument.id),
                          hoverClass: "hover:bg-cyan-100",
                        },
                          {
                              label: 'Send email reminder',
                              onClick: () =>handleRequestEmail(userHrDocument.id),
                              hoverClass: "hover:bg-cyan-100"
                          }
                      ]
                    : []),
                ];
                if (loading) {
                  return (
                    <TableRow key={userHrDocument.id}>
                      <td colSpan={6}>
                        <Skeleton count={1} height={40} />
                      </td>
                    </TableRow>
                  );
                } else {
                  return (
                    <TableRow
                      key={userHrDocument.id}
                      className="border-t border-slate-200 ">
                      <td scope="col" className="pt-3.5 flex justify-center">
                        <input
                          type="checkbox"
                          className="border border-gray-300 rounded text-white disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600
                                           dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                          onClick={e => e.stopPropagation()}
                        />
                      </td>
                      {visibleColumns.userName && (
                        <TableCell>
                          <div className=" relative group">
                            <div className="w-full flex items-center gap-x-3">
                              <div className="flex-grow">
                                <span
                                  className="text-smfont-medium underline text-cyan-600 cursor-pointer"
                                  onClick={handleOpenUserPage(
                                    userHrDocument.user?.id,
                                  )}>
                                  {userHrDocument.user?.name}
                                </span>
                              </div>
                            </div>
                          </div>
                        </TableCell>
                      )}
                      {visibleColumns.name && (
                        <TableCell>
                          <div className=" relative group">
                            <div className="w-full flex items-center gap-x-3">
                              <div className="flex-grow">
                                <span
                                  className="text-smfont-medium underline text-cyan-600 cursor-pointer"
                                  onClick={() => handleEdit(userHrDocument.id)}>
                                  {userHrDocument.name}
                                </span>
                              </div>
                            </div>
                          </div>
                        </TableCell>
                      )}
                      {visibleColumns.expDate && (
                        <TableCell>
                          {userHrDocument.expiresAt || "NA"}
                        </TableCell>
                      )}

                      {visibleColumns.status && (
                        <TableCell>
                          <span
                            className={`flex items-center text-xs font-medium px-2 py-0.5 rounded-full w-28
                                    ${userHrDocument.status === "Complete" ? "bg-green-100 text-green-800" : ""}
                                    ${userHrDocument.status === "Missing" ? "bg-red-100 text-red-800" : ""}
                                    ${userHrDocument.status === "Expiring soon" ? "bg-yellow-100 text-yellow-800" : ""}
                                    ${userHrDocument.status === "Expired" ? "bg-red-100 text-red-800" : ""}
                                    `}>
                            {userHrDocument.status === "Complete" && (
                              <CheckCircleIcon className="mr-2" />
                            )}
                            {userHrDocument.status === "Missing" && (
                              <XCircleIcon className="mr-2" />
                            )}
                            {userHrDocument.status === "Expiring soon" && (
                              <CircleAlertIconIcon className="mr-2" />
                            )}
                            {userHrDocument.status === "Expired" && (
                              <XCircleIcon className="mr-2" />
                            )}

                            <div className="pl-1">{userHrDocument.status}</div>
                          </span>
                        </TableCell>
                      )}

                      {visibleColumns.jobRole && (
                        <TableCell>
                          {userHrDocument.user?.jobRoles
                            .map((role: JobRole) => role.name)
                            .join(", ")}
                        </TableCell>
                      )}
                      {visibleColumns.supervisor && (
                        <TableCell>
                          {userHrDocument.user?.supervisors
                            ?.map((supervisor: any) => supervisor.name)
                            .join(", ")}
                        </TableCell>
                      )}
                      {visibleColumns.email && (
                        <TableCell>{userHrDocument.user?.email}</TableCell>
                      )}

                      <TableCell>
                        <DotsMenu options={userOptions} />
                      </TableCell>
                    </TableRow>
                  );
                }
              })}
            {userHrDocuments.length < 10 &&
              !loading &&
              [...Array(10 - userHrDocuments.length)].map((_, index) => (
                <TableRow key={index}>
                  <td
                    colSpan={
                      Object.entries(visibleColumns).filter(
                        ([, value]) => value,
                      ).length
                    }
                    className="h-10"></td>
                </TableRow>
              ))}
          </tbody>
        </Table>
        <AttachmentModal />
      </>
    );
};
export default DashboardHrDocumentsTable;
