import React from 'react';

const BrowseFileIcon = () => (

<svg width="70" height="52" viewBox="0 0 70 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Image">
        <path id="Vector 129"
              d="M6.05172 8.74549L17.2131 6.88525V40.7377L12.3018 41.7717C9.01306 42.464 5.79705 40.3203 5.17081 37.0184L1.14319 15.7819C0.515988 12.4748 2.73148 9.29886 6.05172 8.74549Z"
              stroke="currentColor" strokeWidth="2"/>
        <path id="Vector 131"
              d="M63.9483 8.74549L52.7869 6.88525V40.7377L57.6982 41.7717C60.9869 42.464 64.203 40.3203 64.8292 37.0184L68.8568 15.7819C69.484 12.4748 67.2685 9.29886 63.9483 8.74549Z"
              stroke="currentColor" strokeWidth="2"/>
        <g id="Rectangle 1" filter="url(#filter0_dd_544_5637)">
            <rect x="17.0656" y="1" width="35.8689" height="42.7541" rx="5" stroke="currentColor" strokeWidth="2"
                  shapeRendering="crispEdges"/>
        </g>
        <path id="Rectangle 2"
              d="M47.9344 43.7541H22.0656C19.3042 43.7541 17.0656 41.5155 17.0656 38.7541L17.0656 35.293L29.4724 22.1452L38.9826 33.0891C39.7833 34.0105 41.2154 34.0059 42.0102 33.0794L47.2456 26.9765L52.9344 33.0979V38.7541C52.9344 41.5155 50.6959 43.7541 47.9344 43.7541Z"
              fill="#EFF6FF" stroke="currentColor" strokeWidth="2"/>
        <circle id="Ellipse 139" cx="39.5902" cy="14.3442" r="4.16393" fill="#EFF6FF" stroke="currentColor"
                strokeWidth="2"/>
    </g>
    <defs>
        <filter id="filter0_dd_544_5637" x="13.0656" y="0" width="43.8689" height="51.7541" filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                           result="hardAlpha"/>
            <feOffset dy="2"/>
            <feGaussianBlur stdDeviation="1"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_544_5637"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                           result="hardAlpha"/>
            <feOffset dy="4"/>
            <feGaussianBlur stdDeviation="1.5"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"/>
            <feBlend mode="normal" in2="effect1_dropShadow_544_5637" result="effect2_dropShadow_544_5637"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_544_5637" result="shape"/>
        </filter>
    </defs>
</svg>
);
export default BrowseFileIcon;
