import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "store/store";
import TextInput from "components/inputs/TextInput";
import {Location, TimeZone} from "types/location";
import SubmitButton from "components/SubmitButton";
import {LocationThunks} from "features/location/locationThunks";
import {setIsEditing} from "features/location/locationSlice";
import {useLoadScript, Autocomplete} from "@react-google-maps/api";
import DescriptionInput from "components/inputs/DescriptionInput";
import PhoneNumberInput from "components/inputs/PhoneNumberInput";
import CustomSelect from "components/inputs/CustomSelect";
import XIcon from "assets/images/icons/XIcon";


interface LocationFormProps {
    id?: string;
    closeDrawer: () => void;
    phoneNumber?: string;
}

const LocationForm: React.FC<LocationFormProps> = ({closeDrawer}) => {
    const location = useSelector((state: RootState) => state.location.location);
    const dispatch = useDispatch<AppDispatch>();
    const [updatedLocation, setUpdatedLocation] = useState({...location});
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
    const {isLoaded} = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
        libraries: ["places"]
    });
    const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);
    const [phoneNumber, setPhoneNumber] = useState(updatedLocation.phoneNumber || "");

    const TimeZoneOptions = TimeZone.map(timeZone => ({
        value: timeZone,
        label: timeZone
    }));

    useEffect(() => {
        const isFormValid = (
            updatedLocation.name !== "" &&
            updatedLocation.description &&
            updatedLocation.phoneNumber &&
            updatedLocation.address &&
            updatedLocation.timeZone &&
            updatedLocation.taxId &&
            updatedLocation.taxonomyCode &&
            updatedLocation.npi
        );

        setIsSaveDisabled(!isFormValid);
    }, [
        updatedLocation.name,
        updatedLocation.description,
        updatedLocation.phoneNumber,
        updatedLocation.address,
        updatedLocation.timeZone,
        updatedLocation.taxId,
        updatedLocation.taxonomyCode,
        updatedLocation.npi,
    ]);

    useEffect(() => {
        setUpdatedLocation({...location});
        setPhoneNumber(location?.phoneNumber || "")
    }, [location]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> | {
        target: { id: string, value: any }
    }) => {
        const {id, value} = e.target;
        setUpdatedLocation({...updatedLocation, [id]: value});
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (location?.id) {
            await dispatch(LocationThunks.update(new Location(updatedLocation))).then(() => closeDrawer());
        } else {
            await dispatch(LocationThunks.create(new Location(updatedLocation))).then(() => closeDrawer());
        }
    };

    const handleClose = () => {
        dispatch(setIsEditing(false));
        setAutocomplete(null);
        setUpdatedLocation((prev: Location) => ({...prev, address: ""}));
        closeDrawer();
    };

    const handleClickOutsideMaps = () => {
        const element = document.querySelector('.pac-container');
        if (element) {
            element.setAttribute('style', 'display: none');
        }
    };

    const onPlaceChanged = () => {
        if (autocomplete) {
            const place = autocomplete.getPlace();
            const newLocation = {...updatedLocation};

            if (place.formatted_address) {
                newLocation.address = place.formatted_address;
            }

            setUpdatedLocation(newLocation);
        }
    };

    return (
        <div className="flex flex-col h-screen">
            <div className="flex-1 flex flex-col ">
                <div className="h-auto p-6 bg-sky-50 border-b border-sky-200 flex justify-between items-start gap-2 w-full">
                    <div className="flex flex-col justify-start items-start gap-1">
                        <div className="text-lg font-semibold tracking-normal text-cyan-800">
                            {location?.id ? 'Edit location' : 'Create new location'}
                        </div>

                        <div className="text-sm font-light tracking-normal leading-5 text-zinc-400">
                            {location?.id ? 'Edit ' : 'Create a new '} company location
                        </div>
                    </div>
                    <button onClick={handleClose} className="text-gray-500 hover:text-gray-700 focus:outline-none pt-2">
                        <XIcon/>
                    </button>
                </div>
                <div className="flex-1 px-3 pb-6 pt-3 overflow-y-auto">
                    <div className="h-full flex flex-col overflow-y-auto overflow-hidden">
                        <div className="h-full px-4">
                            <form onSubmit={handleSubmit}>
                                <div className="flex flex-col space-y-6 items-center mt-4 ">
                                    <div className="w-full">
                                        <TextInput
                                            id="name"
                                            type="text"
                                            label="Location name"
                                            value={updatedLocation?.name}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="w-full">
                                        <DescriptionInput
                                            id="description"
                                            type="text"
                                            label="Description"
                                            value={updatedLocation?.description}
                                            onChange={handleChange}
                                            optional="optional"
                                        />
                                    </div>
                                    <div className="w-full">
                                        <PhoneNumberInput
                                            id="phoneNumber"
                                            label="Phone Number"
                                            value={updatedLocation?.phoneNumber || ""}
                                            onChange={handleChange}
                                            phoneNumber={phoneNumber}
                                            setPhoneNumber={setPhoneNumber}
                                        />
                                    </div>
                                    <div className="grid grid-cols-2 gap-3 w-full">
                                        <div className="w-full">
                                            {isLoaded && (
                                                <Autocomplete
                                                    onLoad={(autocomplete) => setAutocomplete(autocomplete)}
                                                    onPlaceChanged={onPlaceChanged}
                                                    onUnmount={() => setAutocomplete(null)}
                                                >
                                                    <TextInput
                                                        id="address"
                                                        type="text"
                                                        label="Address"
                                                        value={updatedLocation?.address}
                                                        onChange={handleChange}
                                                        onBlur={handleClickOutsideMaps}
                                                    />
                                                </Autocomplete>
                                            )}
                                        </div>
                                        <div className="flex flex-col gap-[0.625rem] w-full">
                                            <label
                                                htmlFor="timeZone"
                                                className="block grow shrink basis-0 text-slate-800 text-sm font-semibold font-['Inter'] dark:text-white"
                                            >
                                                Time zone
                                            </label>
                                            <CustomSelect
                                                options={TimeZoneOptions}
                                                onChange={(selectedOption) => {
                                                    handleChange({
                                                        target: {
                                                            id: 'timeZone',
                                                            value: selectedOption ? selectedOption.value : '',
                                                        }
                                                    });
                                                }}
                                                value={updatedLocation.timeZone}
                                            />
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-3 gap-3 w-full">
                                        <div>
                                            <TextInput
                                                id="taxId"
                                                type="text"
                                                label="Tax ID"
                                                value={updatedLocation?.taxId}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div>
                                            <TextInput
                                                id="taxonomyCode"
                                                type="text"
                                                label="Taxonomy code"
                                                value={updatedLocation?.taxonomyCode}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div>
                                            <TextInput
                                                id="npi"
                                                type="text"
                                                label="NPI"
                                                value={updatedLocation?.npi}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>


                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <footer className="flex-shrink-0 px-6 pb-4 pt-5 flex justify-end gap-5 ">
                    <div className="border-t border-slate-200 w-full">
                        <div
                            className="flex w-[487px]justify-center items-center gap-x-2 pb-4">
                            <SubmitButton onClick={handleSubmit}
                                          label={(location?.id ? 'Save' : 'Create new') + ' location'}
                                          disabled={isSaveDisabled}
                            />
                        </div>
                        <div
                            className="flex w-full justify-center items-center gap-x-2 pb-4">
                            <button type="submit" onClick={handleClose}
                                    className="self-center text-sm font-light tracking-normal leading-5 text-center text-zinc-400 hover:text-cyan-400">
                                Never mind
                            </button>
                        </div>
                    </div>
                </footer>

            </div>
        </div>
    );
};

export default LocationForm;
