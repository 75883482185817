import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "store/store";
import React, {useEffect, useState} from "react";
import TextInput from "components/inputs/TextInput";
import {Credential} from "types/credential";
import SubmitButton from "components/SubmitButton";
import {CredentialThunks} from "features/credential/credentialThunks";
import {setIsEditing} from "features/credential/credentialSlice";
import DescriptionInput from "components/inputs/DescriptionInput";
import XIcon from "assets/images/icons/XIcon";
import LinksIcon from "assets/images/icons/LinksIcon";
import PlusIcon from "assets/images/icons/PlusIcon";
import RadioInputHorizontal from "components/inputs/RadioInputHorizontal";

interface CredentialFormProps {
    id?: string;
    closeDrawer: () => void;
}

const CredentialForm: React.FC<CredentialFormProps> = ({closeDrawer}) => {
    const credential = useSelector((state: RootState) => state.credential.credential);
    const dispatch = useDispatch<AppDispatch>();
    const [updatedCredential, setUpdatedCredential] = useState({...credential});
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
    const [trainingLinksInput, setTrainingLinksInput] = useState("");


    useEffect(() => {
        const isFormValid =
            updatedCredential.name &&
            updatedCredential.expires !== undefined &&
            updatedCredential.requiresTraining !== undefined &&
            (updatedCredential.expires === false ||
                (updatedCredential.remindHr !== undefined &&
                    updatedCredential.remindUser !== undefined)) &&
            (updatedCredential.requiresTraining === false ||
                (updatedCredential.trainingLinks && updatedCredential.trainingLinks.length > 0));

        setIsSaveDisabled(!isFormValid);
    }, [
        updatedCredential.name,
        updatedCredential.expires,
        updatedCredential.remindHr,
        updatedCredential.remindUser,
        updatedCredential.requiresTraining,
        updatedCredential.trainingLinks
    ]);

    useEffect(() => {
        setUpdatedCredential({...credential})
        setIsSaveDisabled(!credential?.nameAlias);
    }, [credential]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const {id, value} = e.target;
        setUpdatedCredential({...updatedCredential, [id]: value});
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (credential?.id) {
            await dispatch(CredentialThunks.update(new Credential(updatedCredential))).then(() => closeDrawer());
        } else {
            await dispatch(CredentialThunks.create(new Credential(updatedCredential))).then(() => closeDrawer());
        }
    };
    const handleRadioChange = (field: string, value: boolean) => {
        setUpdatedCredential((prevState: Credential) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleClose = () => {
        setUpdatedCredential({})
        dispatch(setIsEditing(false));
        closeDrawer();
    };
    const handleAdd = () => {
        if (trainingLinksInput.trim()) {
            setUpdatedCredential({
                ...updatedCredential,
                trainingLinks: [...(updatedCredential.trainingLinks || []), ...[trainingLinksInput.trim()]],

            });
            setTrainingLinksInput("");
        }
    };
    const handleRemove = (index: number) => {
        setUpdatedCredential({
            ...updatedCredential,
            trainingLinks: updatedCredential.trainingLinks?.filter((_: any, i: number) => i !== index),
        });
    }

    return (
        <div className="flex flex-col h-screen">
            <div className="flex-1 flex flex-col">
                <div
                    className="h-auto p-6 bg-sky-50 border-b border-sky-200 flex justify-between items-start gap-2 w-full">
                    <div className="flex flex-col justify-start items-start gap-1">
                        <div className="text-lg font-semibold tracking-normal text-cyan-800">
                            {credential?.id ? 'Edit certification' : 'Create new certification'}
                        </div>

                        <div className="text-sm font-light tracking-normal leading-5 text-zinc-400">
                            {credential?.id ? 'Edit' : 'Create '} certification, associates licenses and reminders
                        </div>
                    </div>
                    <button onClick={handleClose} className="text-gray-500 hover:text-gray-700 focus:outline-none pt-2">
                        <XIcon/>
                    </button>
                </div>

                <div className="h-full px-6 pb-6 ">
                    <div className="mt-5">
                        <form onSubmit={handleSubmit}>
                            <div className="flex flex-col space-y-6 items-center mt-7">
                                <div className="w-full">
                                    <TextInput
                                        id="name"
                                        type="text"
                                        label="Name"
                                        value={updatedCredential?.name}
                                        placeholder=""
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="w-full">
                                    <DescriptionInput
                                        id="description"
                                        type="text"
                                        label="Description"
                                        value={updatedCredential?.description}
                                        placeholder=""
                                        onChange={handleChange}
                                        optional="optional"
                                    />
                                    <div className="w-full mt-6">
                                        <TextInput
                                            id="licensingAuthority"
                                            optional="Optional"
                                            type="text"
                                            label="Licensing Authority"
                                            value={updatedCredential?.licensingAuthority || ""}
                                            placeholder=""
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="space-y-6 items-center mt-7">
                                        <RadioInputHorizontal
                                            label="Expires"
                                            options={[
                                                {id: 'expiresYes', label: 'Yes', value: true},
                                                {id: 'expiresNo', label: 'No', value: false}
                                            ]}
                                            selectedValue={updatedCredential.expires}
                                            onChange={(value) => handleRadioChange('expires', value)}
                                        />

                                        {updatedCredential.expires && (
                                            <>
                                                <div className="flex flex-col space-y-2 ml-3">
                                                    <div className="flex items-start justify-between">
                                                        <div className="flex-1 w-60">
                                                            <RadioInputHorizontal
                                                                label="Remind HR before expiration?"
                                                                options={[
                                                                    {
                                                                        id: "remindHrYes",
                                                                        label: "Yes",
                                                                        value: true,
                                                                    },
                                                                    {
                                                                        id: "remindHrNo",
                                                                        label: "No",
                                                                        value: false,
                                                                    },
                                                                ]}
                                                                selectedValue={updatedCredential.remindHr}
                                                                onChange={(value) =>
                                                                    handleRadioChange("remindHr", value)
                                                                }
                                                            />
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="flex flex-col  space-y-2 ml-3 ">
                                                    <div className="flex items-start justify-between">
                                                        <div className="flex-1 w-60">
                                                            <RadioInputHorizontal
                                                                label="Remind staff before expiration?"
                                                                options={[
                                                                    {
                                                                        id: "remindUserYes",
                                                                        label: "Yes",
                                                                        value: true,
                                                                    },
                                                                    {
                                                                        id: "remindUserNo",
                                                                        label: "No",
                                                                        value: false,
                                                                    },
                                                                ]}
                                                                selectedValue={
                                                                    updatedCredential.remindUser
                                                                }
                                                                onChange={(value) =>
                                                                    handleRadioChange("remindUser", value)
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        <RadioInputHorizontal
                                            label="Requires training?"
                                            options={[
                                                {id: 'requiresTrainingYes', label: 'Yes', value: true},
                                                {id: 'requiresTrainingNo', label: 'No', value: false}
                                            ]}
                                            selectedValue={updatedCredential.requiresTraining}
                                            onChange={(value) => handleRadioChange('requiresTraining', value)}
                                        />
                                        {updatedCredential.requiresTraining && (
                                            <div className="flex items-center space-x-2 space-y-2 ml-3">
                                                <div className="flex-1">
                                                    <TextInput
                                                        id="trainingLinks"
                                                        type="text"
                                                        icon={<LinksIcon/>}
                                                        label="Training Links"
                                                        value={trainingLinksInput}
                                                        onChange={(e) => setTrainingLinksInput(e.target.value)}
                                                    />
                                                </div>
                                                <div className="pt-5 ">
                                                    <button
                                                        onClick={handleAdd}
                                                        className="py-3 px-3 h-full inline-flex items-center text-xs rounded-lg shadow
                                                               disabled:opacity-50 focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 dark:border-neutral-700
                                                               dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 relative
                                                               border border-slate-200  text-slate-800 hover:bg-cyan-100
                                                               disabled:pointer-events-none disabled:bg-zinc-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600">
                                                        <PlusIcon/>
                                                    </button>
                                                </div>
                                            </div>

                                        )}
                                        {updatedCredential.requiresTraining && updatedCredential.trainingLinks?.map((link: string) => {
                                            return (
                                                <div
                                                    key={link}
                                                    className="h-10 w-full p-3 bg-slate-50 rounded-lg border border-gray-200 justify-start items-center gap-5 inline-flex">
                                                    <div
                                                        className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
                                                        <div
                                                            className="self-stretch pr-9 justify-start items-start gap-2.5 inline-flex">
                                                            <div
                                                                className="grow shrink basis-0 text-gray-600 text-base font-normal font-['Inter'] leading-normal tracking-tight">

                                                                {link}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button
                                                        className="text-gray-500 hover:text-gray-700 focus:outline-none "
                                                        onClick={() => handleRemove(updatedCredential.trainingLinks?.indexOf(link))}>
                                                        <XIcon/>
                                                    </button>
                                                </div>

                                            );
                                        })}

                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <footer className="flex-shrink-0 px-6 pb-4 pt-2 flex justify-end gap-5">
                <div className="border-t border-slate-200 w-full">
                    <div
                        className="flex w-full justify-center items-center gap-x-2 pb-4">
                        <SubmitButton onClick={handleSubmit}
                                      label={(credential?.id ? 'Save' : 'Create new') + ' certification'}
                                      disabled={isSaveDisabled}
                        />
                    </div>
                    <div
                        className="flex w-full justify-center items-center gap-x-2 pb-4">
                        <button type="submit" onClick={handleClose}
                                className="self-center text-sm font-light tracking-normal leading-5 text-center text-zinc-400 hover:text-cyan-400">
                            Never mind
                        </button>
                    </div>
                </div>
            </footer>
        </div>

    );
};

export default CredentialForm;
