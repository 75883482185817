// TableInputProperties.tsx
import React from 'react';
import {TableInputElement} from 'types/documentTemplateElement';
import RadioInputHorizontal from '../../inputs/RadioInputHorizontal';
import TextInput from '../../inputs/TextInput';
import {DocumentTemplate} from "../../../types/documentTemplate";

interface TableInputPropertiesProps {
    element: TableInputElement;
    updateElement: (updatedElement: TableInputElement) => void;
    template: DocumentTemplate;

}

const TableInputProperties: React.FC<TableInputPropertiesProps> = ({
                                                                       element,
                                                                       updateElement,
                                                                        template
                                                                   }) => {
    const { label, rows, columns, showLabel } = element.properties;

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const {id, value} = e.target;
    const parsedValue =
      id === 'rows' || id === 'columns' ? parseInt(value, 10) || 0 : value;

    updateElement({
      ...element,
      properties: {
        ...element.properties,
        [id]: parsedValue,
      },
    });
  };

  const handleShowLabelChange = (value: boolean) => {
    updateElement({
      ...element,
      properties: {
        ...element.properties,
        showLabel: value,
      },
    });
  };

  return (
    <div className="space-y-5 w-full">
      <RadioInputHorizontal
        label="Show Label?"
        options={[
          {id: 'show-label-yes', label: 'Yes', value: true},
          {id: 'show-label-no', label: 'No', value: false},
        ]}
        selectedValue={showLabel}
        onChange={handleShowLabelChange}
      />

      {showLabel && (
        <TextInput
          id="label"
          type="text"
          label="Table Label"
          value={label}
          onChange={handleChange}
        />
      )}

            <div className="flex gap-4">
                <TextInput
                    id="rows"
                    type="number"
                    label="Rows"
                    value={rows.toString()}
                    onChange={handleChange}
                />
                <TextInput
                    id="columns"
                    type="number"
                    label="Columns"
                    value={columns.toString()}
                    onChange={handleChange}
                />
            </div>
            {template.requiresReview && (
                <RadioInputHorizontal
                    label="Admin Only"
                    options={[
                        {id: 'adminOnly-yes', label: 'Yes', value: true},
                        {id: 'adminOnly-no', label: 'No', value: false},
                    ]}
                    selectedValue={element.properties.adminOnly}
                    onChange={(value) => updateElement({...element, properties: {...element.properties, adminOnly: value}})}
                />
            )}
        </div>
    );
};

export default TableInputProperties;
