import React from 'react';
import {ElementProps, EmailInputElementClass} from "types/documentTemplateElement";
import TextInput from "../../inputs/TextInput";
import EmailIcon from "../../../assets/images/icons/EmailIcon";

interface EmailInputElementProps extends ElementProps<EmailInputElementClass> {}

const EmailInputElementComponent: React.FC<EmailInputElementProps> = ({ element }) => {
    return (
        <div className="w-full m-8">
            <TextInput
                id={element.id}
                type="text"
                label={element.properties.showLabel ? element.properties.label : ""}
                value=""
                icon={<EmailIcon/>}
                placeholder={element.properties.placeholder}
                required={element.properties.required}
                onChange={() => {}}
            />
        </div>
    );
};

export default EmailInputElementComponent;
