import React from 'react';

interface LockedProps {
    className?: string
}

const LockedIcon = ({}: LockedProps) => (

    <svg width="14" height="14" strokeWidth="2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="pause-circle">
            <path id="Vector"
                  d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                  stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
            <path id="Vector_2" d="M10 15V9" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
            <path id="Vector_3" d="M14 15V9" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
    </svg>

);
export default LockedIcon;
