import {useRef, useEffect, useState} from 'react';

const useMinHeight = () => {
  const [minHeight, setMinHeight] = useState<string | number>('auto');
  const [minContentHeight, setMinContentHeight] = useState<string | number>(
    'auto',
  );
  const contentRef = useRef<HTMLDivElement | null>(null);

  const updateHeights = () => {
    if (contentRef.current) {
      const vh = Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0,
      );
      const maxHeight = `calc(${Math.max(vh, contentRef.current.offsetHeight)}px - 4rem)`;

      setMinHeight(contentRef.current.offsetHeight);
      setMinContentHeight(maxHeight);
    }
  };

  useEffect(() => {
    updateHeights();
    window.addEventListener('resize', updateHeights);
    return () => {
      window.removeEventListener('resize', updateHeights);
    };
  }, []);

  return {minHeight, contentRef, minContentHeight, updateHeights};
};

export default useMinHeight;
